/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Path, useLocation, useNavigate } from "react-router-dom";
import { NavigateOptions } from "react-router/lib/hooks";
import { useCallback } from "react";

/**
 * Returns function to change only parts (pathname, search, hash) of current location.
 * Does not preserve `location.state`!
 *
 * @returns {(to: Partial<Path>, options?: NavigateOptions) => void}
 */
export const useNavigatePreserving: () => (to: Partial<Path>, options?: NavigateOptions) => void = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
      (to: Partial<Path>, options?: NavigateOptions) => navigate(
          {pathname: location.pathname, search: location.search, hash: location.hash, ...to},
          options
      ),
      [navigate] // do not add location!
  );
};
