/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ReactNode } from "react";
import classNames from "classnames";
import "./DHLDialog.scss";

type DHLDialogNonModalProps = {
    children?: ReactNode;
    /**
     * Actionbar/Button-Bereich
     *
     * Design-Hinweis:
     * Alles btn-normal.
     * Wenn es eine primäre und eine sekundäre Aktion gibt, steht die
     *  sekundäre Aktion als erstes und die primäre Aktion als zweites.
     * Wenn es neben primärer und sekundärer Aktion noch weitere gibt, werden diese als "Secondary Buttons"
     *  dargestellt und zwischen primärer und sekundärer Aktion eingefügt.
     */
    actionbar?: ReactNode;
}

export const DHLDialogNonModal =  ({children, actionbar}: DHLDialogNonModalProps) => {

    return <div className={classNames("row", "dhlDialogContainer", "dhlDialogContainer-NonModal")}>
        <div className={classNames("col", "dhlDialog", "dhlDialog-NonModal")}>
            {children}
            <div className={"dhlDialog-actionbar"}>{actionbar}</div>
        </div>
    </div>;
};
