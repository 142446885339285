import { MouseEventHandler, ReactChild } from "react";
import { uid } from "react-uid";
import { DHLCancelButton, DHLSubmitButton } from "../../atoms/DHLButton/DHLButton";
import { DHLButtonGroup } from "../../atoms/DHLButtonGroup/DHLButtonGroup";

export type DHLTabButtonBarProps = {
  /** CSS Klassen. */
  className?: string;

  /** Neuanlagemodus. */
  createMode?: boolean;

  /** Erster Tab aktiv. */
  firstTab?: boolean;

  /** Letzter Tab aktiv. */
  lastTab?: boolean;

  /** Label für Cancel-Button. */
  cancelLabel: string;

  /** Funktion für Cancel onClick-Aufrufe. */
  onCancelClick: MouseEventHandler<HTMLElement>;

  /** Label für Submit-Button. */
  submitLabel?: string;

  /** Funktion für Submit onClick-Aufrufe. */
  onSubmitClick?: MouseEventHandler<HTMLElement>;

  /** Label für Next-Button. */
  nextLabel?: string;

  /** Funktion für Next onClick-Aufrufe. */
  onNextClick?: MouseEventHandler<any>;

  /** Label für Previous-Button. */
  previousLabel?: string;

  /** Funktion für Previous onClick-Aufrufe. */
  onPreviousClick?: MouseEventHandler<any>;

  /** Kind-Komponenten. */
  children?: never;
};

/** DHLTabButtonBar. */
export const DHLTabButtonBar = (
    {
      className,
      createMode = false,
      firstTab = false,
      lastTab = false,
      cancelLabel,
      onCancelClick,
      submitLabel,
      onSubmitClick,
      nextLabel,
      onNextClick,
      previousLabel,
      onPreviousClick
    }: DHLTabButtonBarProps
) => {
  const buttons: ReactChild[] = [];
  const showPrevButton = createMode && !firstTab && !(firstTab && lastTab) && previousLabel && onPreviousClick;
  const showNextButton = createMode && !lastTab && !(firstTab && lastTab) && nextLabel && onNextClick;
  const showSubmitButton = (!createMode || lastTab) && submitLabel && onSubmitClick;

  buttons.push(<DHLCancelButton key={uid("tbg-cancel")} label={cancelLabel} onClick={onCancelClick} />);

  if (showPrevButton) {
    buttons.push(<DHLSubmitButton key={uid("tbg-previous")} label={previousLabel} onClick={onPreviousClick} />);
  }

  if (showNextButton) {
    buttons.push(<DHLSubmitButton key={uid("tbg-next")} label={nextLabel} onClick={onNextClick} />);
  }

  if (showSubmitButton) {
    buttons.push(<DHLSubmitButton key={uid("tbg-submit")} label={submitLabel} onClick={onSubmitClick} />);
  }

  return (
      <DHLButtonGroup className={className}>
        {buttons}
      </DHLButtonGroup>
  );
};
