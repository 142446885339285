/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useCallback } from "react";
import { useSharedSearchParams } from "./useSharedSearchParams";

export const useSharedSearchParam:(name: string) => [string | null, (value: string | null) => void] = (name) => {
  const [searchParams, setSearchParams] = useSharedSearchParams();
  const setValue = useCallback(
      (value: string | null) => {
        if(value === null) {
          searchParams.delete(name);
        } else {
          searchParams.set(name, value);
        }
        setSearchParams(searchParams);
      },
      [searchParams, setSearchParams]
  );
  return [searchParams.get(name), setValue];
};
