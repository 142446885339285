/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { MouseEventHandler, ReactNode, useRef, useState } from "react";
import { Placement } from "popper.js";
import { Button } from "reactstrap";
import { normalizeName } from "../../../utils/stringUtils";
import { DHLTooltip } from "../../molecules/DHLTooltip/DHLTooltip";
import { DHLIcon, IconType } from "../DHLIcon/DHLIcon";
import "./DHLButton.scss";

/** Konstante für den DEFAULT-Wert für die Buttongröße. */
export const DEFAULT_BUTTON_SIZE = "normal";
/** Konstante für den DEFAULT-Wert für den Ausprägungstyp. */
export const DEFAULT_BUTTON_TYPE = "default";
/** Konstante für den DEFAULT-Wert für den Iconausprägungstyp. */
export const DEFAULT_BUTTON_ICON_POSITION = "no-icon";

/** WICHTIG: Kein extends verwenden, da Storybook damit nicht umgehen kann und die Attribute der übergeordneten Interfaces/Typen nicht anzeigt. */
export type DHLButtonProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;
  /** Buttontext. */
  label?: string;
  /** Funktion für onClick-Aufrufe. */
  onClick: MouseEventHandler<HTMLElement>;
  /** Tooltiptext. */
  tooltip?: string | ReactNode;
  /** Ausrichtung des Tooltips. */
  tooltipPlacement?: Placement;
  /** Tooltip, wenn Button disabled. */
  disabledTooltip?: string | ReactNode;
  /** CSS-Klassen. */
  className?: string;
  /** Größe (mögliche Werte: lg, normal, sm, xs; Defaultwert ist 'normal') */
  size?: "lg" | "normal" | "normalWithLargeIcon" | "sm" | "sm-selectmodule" | "xs";
  /** Ausprägungstyp (mögliche Werte: default, primary, secondary; Defaultwert 'default') */
  type?: "default" | "primary" | "gogreen" | "secondary" | "selectmodule" | "ghost";
  /** icon position (allowed values: no-icon, icon, icon-first, icon-last, icon-button; default 'no-icon').
   * Note: icon is GKDS icon-only button, icon-button is icon button (aka button with only icon)!
   */
  iconPosition?: "no-icon" | "icon" | "icon-first" | "icon-last" | "icon-button";
  /** Flag zum deaktivieren des Buttons. */
  disabled?: boolean;
  /** Flag, ob der Button überhaupt gerendert werden soll. */
  render?: boolean;
  /** Name des Icons . */
  icon?: IconType;
  /** Loading state/animation? Also blocks clicks like disabled-state */
  loadingState?: boolean;
  /** Tooltip, wenn Button in loading-state. */
  loadingTooltip?: string;
};

/** Button mit integriertem Tooltip. */
export const DHLButton = ({
                            name,
                            label,
                            onClick,
                            tooltip,
                            tooltipPlacement = "right",
                            disabledTooltip,
                            className,
                            size = DEFAULT_BUTTON_SIZE,
                            type = DEFAULT_BUTTON_TYPE,
                            iconPosition = DEFAULT_BUTTON_ICON_POSITION,
                            disabled,
                            render = true,
                            icon = "close-thin",
                            loadingState = false,
                            loadingTooltip
                          }: DHLButtonProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(prevTooltipOpen => !prevTooltipOpen);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  if (!render) {
    return null;
  }
  let usedTooltip = tooltip;

  if (disabled && disabledTooltip) {
    usedTooltip = disabledTooltip;
  }
  if (loadingState && loadingTooltip) {
    usedTooltip = loadingTooltip;
  }

  const normalizedName = normalizeName(name ?? "");

  return (
      <div id={"container-" + normalizedName} className={classNames(className ? className + "-container" : null, "dhlBtn-container")}>
        <Button
            id={"button-" + normalizedName}
            data-testid={normalizedName}
            color={type}
            onClick={loadingState ? undefined : onClick} disabled={disabled}
            className={classNames(className, "dhlBtn", "dhlBtn-" + type, "dhlBtn-" + iconPosition, "dhlBtn-" + size, {loading: loadingState})}
            innerRef={buttonRef}
        >
          <div id={"button-content-container-" + normalizedName} className={"dhlBtn-content"}>
            {iconPosition === "icon" || iconPosition === "icon-button"
                ? <DHLIcon name={normalizedName + "-button-icon"} icon={icon} />
                : <>
                  {iconPosition === "icon-first" && <DHLIcon name={normalizedName + "-button-icon"} icon={icon} />}
                  <div id={"button-label-container-" + normalizedName} className={"dhlBtn-label-containter"}>{label}</div>
                  {(iconPosition === "icon-last") && <DHLIcon name={normalizedName + "-button-icon"} icon={icon} />}
                </>
            }
          </div>
          {loadingState && <div className="dhlBtn-loader">
            <DHLIcon name="" icon="preloader-no-bg" />
          </div>}
        </Button>
        {/* check if usedTooltip is truthy. React.Node also allows false and this should not render a tooltip*/}
        {(usedTooltip || tooltipOpen) &&
            <DHLTooltip
                testid={normalizedName + "-tooltip"}
                placement={tooltipPlacement}
                tooltipOpen={(usedTooltip && tooltipOpen) || false}
                target={disabled ? "container-" + normalizedName : buttonRef}
                toggle={toggle}>
              {usedTooltip}
            </DHLTooltip>
        }
      </div>
  );
};

/** DHLButton in der Primary Ausprägung. */
export const DHLSubmitButton = (dHLButtonConfiguration: DHLButtonProps) => {
  return (
      <DHLButton type="primary" {...dHLButtonConfiguration} />
  );
};

/** DHLButton in der Secondary Ausprägung. */
export const DHLCancelButton = (dHLButtonConfiguration: DHLButtonProps) => {
  return (
      <DHLButton type="secondary" {...dHLButtonConfiguration} />
  );
};