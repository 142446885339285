/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import moment from "moment";
import { CurrencyType } from "../types/DataTypes";
import { LocaleManager } from "@gkuis/gkp-authentication";

type DecimalDelimiter = "," | ".";

export function getDhlNumberFormattingLocale(localeManager: LocaleManager): string {
  return localeManager.language.includes("de") ? "de-DE" : "en-GB";
}

export function formatNumber(
    localeManager: LocaleManager,
    value: number,
    minFractionDigits: number,
    maxFractionDigits: number,
    groupingEnabled: boolean
) {
  return formatterNumber(localeManager, minFractionDigits, maxFractionDigits, groupingEnabled).format(value);
}

export function formatterNumber(localeManager: LocaleManager, minFractionDigits: number, maxFractionDigits: number, groupingEnabled: boolean) {
  const options = {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
    useGrouping: groupingEnabled
  };

  return new Intl.NumberFormat(getDhlNumberFormattingLocale(localeManager), options);
}

/**
 * @param {LocaleManager} localeManager locale manager
 * @param {number} value the number to be formatted
 * @param {boolean} groupingEnabled decide whether, e.g., "1.000,00" or "1000,00" is shown
 * @param {string} currencyType must conform to ISO 4217, e.g.: "EUR" or "USD"
 * @param {boolean} currencySymbol decide whether, e.g., "EUR" or "€" is shown
 */
export function formatCurrency(
    localeManager: LocaleManager,
    value: number | null,
    groupingEnabled?: boolean,
    currencyType?: CurrencyType,
    currencySymbol?: boolean
) {
  if (null === value) {
    return "";
  }
  // currency type could be derived from localeManager.language here
  return formatterCurrency(
      localeManager,
      currencyType ?? "EUR",
      groupingEnabled ?? true,
      currencySymbol ?? false
  )
      .format(value);
}

export function formatterCurrency(localeManager: LocaleManager, currencyType: string, groupingEnabled: boolean, currencySymbol: boolean) {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: currencyType,
    useGrouping: groupingEnabled,
    currencyDisplay: currencySymbol ? "symbol" : "code"
  };
  return new Intl.NumberFormat(getDhlNumberFormattingLocale(localeManager), options);
}

export function formatDateTime(value: moment.MomentInput, dateFormat: string, timeFormat: string) {
  return moment(value).format(dateFormat + " " + timeFormat);
}

export function formatterDateTime(dateFormat: string, timeFormat: string) {
  return (momentInput: moment.MomentInput) => formatDateTime(momentInput, dateFormat, timeFormat);
}

export function formatDate(value: moment.MomentInput, dateFormat: string) {
  return moment(value).format(dateFormat);
}

export function formatterDate(dateFormat: string) {
  return (momentInput: moment.MomentInput) => formatDate(momentInput, dateFormat);
}

export function formatTime(value: moment.MomentInput, timeFormat: string) {
  return moment(value).format(timeFormat);
}

export function formatterTime(timeFormat: string) {
  return (momentInput: moment.MomentInput) => formatTime(momentInput, timeFormat);
}

export function dateFormatForLanguage(language: string) {
  return "en" === language ? "DD/MM/YYYY" : "DD.MM.YYYY";
}

export function isAnyValidFloat(value: string, numberOfDecimalDigits: number): boolean {
  return isValidFloat(value, numberOfDecimalDigits, ",") || isValidFloat(value, numberOfDecimalDigits, ".");
}

export function isValidFloat(value: string, numberOfDecimalDigits: number, decimalDelimiter: DecimalDelimiter): boolean {
  const groupingDelimiter = decimalDelimiter === "." ? "," : ".";
  const regex = new RegExp("^((\\d{1,3}([" + groupingDelimiter + "]\\d{3})*|\\d*)([" +
      decimalDelimiter + "]\\d{0," + numberOfDecimalDigits + "})?)$");
  return regex.test(value);
}

export function parseFormattedFloat(value: string): number {
  const toParse = isValidFloat(value, 2, ",")
      ? value.replaceAll(".", "").replaceAll(",", ".")
      : value.replaceAll(",", "");
  return parseFloat(toParse);
}
