/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, makeObservable, observable } from "mobx";
import { ParameterizedMessageType } from "../types/ResourceDataStoreTypes";
import { MessageDataStore } from "./MessageDataStore";
import { ResourceDataStore } from "./ResourceDataStore";
import { ValidationRuleDataStore } from "./ValidationRuleDataStore";
import autoBind from "auto-bind";
import { TFunction } from "i18next";
import { ensureOldParams, isTFunc } from "../utils/i18next";
import { $Dictionary } from "i18next/typescript/helpers";
import { ResourceDataStoreTAdapter } from "./ResourceDataStoreTAdapter";
import { FetchAdapter } from "../utils/fetch/FetchAdapter";

export type SuccessResponse = {
  /** Resource-Key der Erfolgsmeldung. */
  successMessageKey?: string | null;

  /** Parameter zur Ersetzung der Platzhalter in der lokalisierten Erfolgsmeldung. */
  successMessageParams?: string[] | null;
};

export type ValidationPropertyResult = {
  propertyNames: string[];

  /** Resource-Key der Fehlermeldung. */
  messageKey: string;

  /** Parameter zur Ersetzung der Platzhalter in der lokalisierten Erfolgsmeldung. */
  parameters?: string[] | $Dictionary | null;
}

export type ErrorResponse = {
  /** Globaler Fehler ohne Bezug zu einem Eingabefeld? */
  globalError?: boolean | null;

  /** Fehlermeldungen. */
  errorMessages?: ParameterizedMessageType[] | null;

  /** Validierungsfehler. */
  validationPropertyResults?: ValidationPropertyResult[] | null;

  /** Parameter zur Ersetzung der Platzhalter in der lokalisierten Erfolgsmeldung. */
  // TODO check
  successMessageParams?: string[] | null;

  /** Correlation-ID of request */
  correlationId?: string;
};

/**
 * Enthält die Stores für die Lokalisierungen und die globalen Erfolgs- und Fehlermeldungen. Zusätzlich wird eine Mapping für lokale Fehlermeldungen
 * verwaltet.
 */
export class BaseDataStore {

  /** Message-Ressource mit lokalisierten Texten. */
  resourceDataStore: ResourceDataStore;

  /** Validierungsregeln. */
  validationRuleDataStore: ValidationRuleDataStore;

  /** Fehler bei den Eingabefeldern anzeigen. */
  showFieldErrors: boolean;

  /** Meldungen für das Meldungs-Widget. */
  messageDataStore: MessageDataStore;

  /** Lokale Fehlermeldungen. */
  localFieldErrors: Map<string, ParameterizedMessageType>;

  /**
   * Konstruktor.
   * @param resourceDataStore Message-Ressource mit lokalisierten Texten
   * @param validationRuleDataStore Validierungsregeln
   * @param messageDataStore wenn ein übergeordneter Message-DataStore verwendet werden soll, dann muss der hier übergeben werden. Andernfalls wird
   * nicht immer der korrekte Store verwendet.
   * @param showFieldErrors true wenn die Fehlermeldungen bei den Eingabefeldern angezeigt werden sollen, sonst false
   */
  constructor(
      resourceDataStore: ResourceDataStore | TFunction,
      validationRuleDataStore: ValidationRuleDataStore,
      messageDataStore: MessageDataStore | null | undefined,
      showFieldErrors: boolean
  ) {
    if (isTFunc(resourceDataStore)) {
      this.resourceDataStore = new ResourceDataStoreTAdapter(new FetchAdapter(), "", "", resourceDataStore);
    } else {
      this.resourceDataStore = resourceDataStore;
    }
    this.validationRuleDataStore = validationRuleDataStore;
    this.showFieldErrors = showFieldErrors;
    this.messageDataStore = messageDataStore ? messageDataStore : new MessageDataStore();
    this.localFieldErrors = new Map();
    makeObservable(this, {
      resourceDataStore: observable,
      validationRuleDataStore: observable,
      messageDataStore: observable,
      localFieldErrors: observable,
      clearTemporaryMessages: action,
      addLocalFieldError: action,
      clearLocalFieldError: action,
      clearAllLocalFieldErrors: action,
      handleErrorResponse: action,
      handleErrorResponseAsGlobal: action,
      handleGlobalErrorResponse: action,
      handleSuccessResponse: action
    });

    autoBind(this);
  }

  /**
   * Alle temporären Erfolgs- und Fehlermeldungen sowie die lokalen Fehlermeldungen löschen.
   */
  clearTemporaryMessages(): void {
    this.messageDataStore.clearTemporaryMessages();
    this.localFieldErrors.clear();
  }

  /**
   * Fügt einen lokalen Validierungsfehler für das angegebene Property hinzu.
   * @param propertyName Propertiesname
   * @param errorMsgKey Lokalisierungsname der Fehlermeldung
   * @param params weitere Parameter zur Fehlermeldung
   * @param markerOnly marker only
   */
  addLocalFieldError(propertyName: string, errorMsgKey: string, params: string[] | null, markerOnly: boolean = false): void {
    if (errorMsgKey) {
      const error: ParameterizedMessageType = {messageKey: errorMsgKey, params: params};

      this.localFieldErrors.set(propertyName, error);

      if (!this.showFieldErrors && !markerOnly) {
        this.messageDataStore.addErrorMessage(this.resourceDataStore.getMessage(errorMsgKey, params ?? undefined));
      }
    }
  }

  /**
   * Entfernt den lokalen Validierungsfehler für das angegebene Property.
   * @param propertyName Propertiesname
   */
  clearLocalFieldError(propertyName: string): void {
    this.localFieldErrors.delete(propertyName);
  }

  /**
   * Entfernt alle lokalen Validierungsfehler.
   */
  clearAllLocalFieldErrors(): void {
    this.localFieldErrors.clear();
  }

  /**
   * Ermittelt den lokalen Validierungsfehler für das angegebene Property.
   * @param propertyName Propertiesname
   * @return Fehlermeldung oder null, wenn kein Fehler vorliegt.
   */
  getLocalFieldError(propertyName: string): string | null {
    const error = this.localFieldErrors.get(propertyName);

    if (error) {
      return this.resourceDataStore.getMessage(error.messageKey, error.params ?? undefined);
    }

    return null;
  }

  /**
   * Prüft, ob mindestens eine lokale Fehlermeldungen vorliegt.
   * @return true wenn ein oder mehrere Fehler vorliegen, sonst false
   */
  hasLocalFieldError(): boolean {
    return this.localFieldErrors.size > 0;
  }

  hasAnyError(): boolean {
    return this.hasLocalFieldError() || this.messageDataStore.errorMsgs.length > 0;
  }

  /**
   */
  /**
   * Verarbeitet eine fehlerhafte Rückgabe des Servers.
   * @deprecated FormFields verwenden und Server-Fehler mit mapServerErrorsToInputFields aus BaseFormStore mappen!
   * @param object Antwort des Servers
   * @param clear true wenn die temporären Meldungen gelöscht werden sollen
   */
  handleErrorResponse(object: ErrorResponse | null, clear: boolean = true): void {
    if (clear) {
      this.clearTemporaryMessages();
    }

    if (object && object.validationPropertyResults) {
      object.validationPropertyResults.forEach((element: any) => {
        element.propertyNames.forEach((propName: string) => {
          if (propName) {
            this.addLocalFieldError(propName, element.messageKey, element.parameters, element.markOnly);
          } else {
            this.messageDataStore.addErrorMessage(this.resourceDataStore.getMessage(element.messageKey, element.parameters)
            );
          }
        });
      });
    }
  }

  /**
   * Behandelt Validierungsfehler für Eingabefelder und zeigt diese als globale Fehlermeldung an. Ist in bestimmten Situationen
   * notwendig, wenn die Felder nicht angezeigt werden, der Server die EIngabe (z.B. URL-Parameter) aber validiert.
   * @param {ErrorResponse | null} object
   */
  handleErrorResponseAsGlobal(object: ErrorResponse | null): void {
    if (object && object.validationPropertyResults) {
      for (let i = 0; i < object.validationPropertyResults.length; i++) {
        const element = object.validationPropertyResults[i];

        if (element.propertyNames.length > 0) {
          this.messageDataStore.addErrorMessage(
              this.resourceDataStore.getMessage(element.messageKey, ensureOldParams(element.parameters) ?? undefined)
          );
        }
      }
    }
  }

  handleGlobalErrorResponse(object: ErrorResponse | null): void {
    object
        ?.errorMessages
        ?.forEach(errorMsg => this.messageDataStore.addErrorMessage(
            this.resourceDataStore.getMessage(errorMsg.messageKey, errorMsg.params)
        ));

    object?.globalError && object?.validationPropertyResults
        ?.filter(validationPropertyResult => validationPropertyResult.propertyNames.includes("_global_"))
        .forEach(validationPropertyResult => this.messageDataStore.addErrorMessage(
            this.resourceDataStore.getMessage(validationPropertyResult.messageKey, ensureOldParams(validationPropertyResult.parameters))
        ));
  }

  /**
   * Verarbeitet eine erfolgreich Rückgabe des Servers.
   * @param object Antwort des Servers
   */
  handleSuccessResponse(object: SuccessResponse | null): void {
    if (object?.successMessageKey) {
      this.clearTemporaryMessages();
      this.messageDataStore.addSuccessMessage(
          this.resourceDataStore.getMessage(object.successMessageKey, object.successMessageParams ?? undefined)
      );
    }
  }
}
