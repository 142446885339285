/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { useRef } from "react";
import { DayOfMonth } from "../../../types/DatePickerTypes";

export type DayProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Wochentag. */
  day: DayOfMonth;

  /** Funktion zur Speicherung des ausgewählten Tages. */
  onDaySelected: (day: DayOfMonth) => void;

  /** Callback function that gets called when the mouse enters the day cell/div */
  onMouseEnterDay?: (day: DayOfMonth) => void;

  /** Callback function that gets called when the mouse leaves the day cell/div */
  onMouseLeaveDay?: (day: DayOfMonth) => void;
};

function getOuterClassNames(day: DayOfMonth) {
  return classNames(day.today ? "current" : null, day.selected ? "selected"
      : null, !day.clickable ? "inactive" : null, day.selected ? "selected"
      : null, day.positionInRange ? day.positionInRange : null);
}

function getInnerClassNames(day: DayOfMonth) {
  return classNames("day", {old: day.old}, {new: day.new}, day.today ? "current" : null, day.selected ? "selected"
      : null, !day.clickable ? "inactive" : null, day.positionInRange ? day.positionInRange : null);
}

/** Stellt einen Tag im Kalender dar. */
export const Day = ({name, day, onDaySelected, onMouseEnterDay, onMouseLeaveDay}: DayProps) => {
  const dayRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  if (day.day === 0) {
    return <td />;
  }

  // border-radius für Kreise: "Das Verhalten bei internen Tabellenelementen ist momentan undefiniert."
  // daher inner div
  return (
      <td className={getOuterClassNames(day)}>
        <div
            ref={dayRef}
            onMouseOver={onMouseEnterDay ? () => onMouseEnterDay(day) : undefined}
            onMouseLeave={onMouseLeaveDay ? () => onMouseLeaveDay(day) : undefined}
            data-testid={name}
            onClick={() => onDaySelected(day)}
            className={getInnerClassNames(day)}>
          {day.day}
        </div>
      </td>
  );
};
