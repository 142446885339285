/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import { logger } from "./logger";
import { Fragment } from "react";
import { DHLActionTextLink } from "../components/atoms/DHLActionTextLink/DHLActionTextLink";
import { NavigateFunction } from "react-router-dom";

/**
 * Erstellt ein Array mit den Bestandteilen des Textes.
 * TextA [TextB|Link] TextC wird zum Array ["TextA", ["TextB", "Link"], "TextC"]
 * TextA [TextB|Link|target] TextC wird zum Array ["TextA", ["TextB", "Link", "target"], "TextC"]
 * TextA [BR] TextB wird zum Array ["TextA", ["br"], "TextB"]
 * TextA [li|TextB] TextC wird zum Array ["TextA", ["li", "TextB"], "TextC]
 * TextA [navigate|TextB|internalLink] TextC wird zum Array ["TextA", ["navigate", "TextB", "internalLink"], "TextC]
 */
export const destructText = (text: string | null): any[] => {
  if (!text) {
    return [];
  }
  let elements = [];
  const startIndex = text.indexOf("[");
  const endIndex = text.indexOf("]", startIndex);
  if (startIndex !== -1 && endIndex !== -1) {
    const textBefore = text.substring(0, startIndex);
    const specialMessageTag = text.substring(startIndex + 1, endIndex);
    const textAfter = text.substring(endIndex + 1, text.length);
    elements.push(textBefore);
    if (specialMessageTag.indexOf("|") !== -1) {
      // should be a link element or an li
      elements.push(specialMessageTag.split("|"));
    } else if (specialMessageTag.toLowerCase() === "br") {
      elements.push([specialMessageTag.toLowerCase()]);
    } else if (specialMessageTag.toLowerCase() === "nbsp") {
      elements.push([specialMessageTag.toLowerCase()]);
    } else {
      logger.warn("Some message contains an unknown specialMessageTag: " + specialMessageTag);
    }
    // recursive call to allow multiple links in a text
    elements = elements.concat(destructText(textAfter));
  } else {
    // push the plain text as array
    elements.push(text);
  }
  return elements;
};

function getTarget(element: string & any[]) {
  return element.length > 2 ? element[2] : "_blank";
}

/**
 * Erstellt React-Komponenten aus Text, Links und LineBreaks (<br />).
 */
export const createMessageWithSpecialTags = (text: null | string | undefined, navigateFunction?: NavigateFunction) => {
  if (!text) {
    return null;
  }
  if (text.indexOf("[") !== -1) {
    const elements = destructText(text);
    return elements.map((element: string, index: number) => {
      if (Array.isArray(element) && element.length > 0) {
        if (element[0] === "b") {
          return <span key={index} className="boldface-text">{element[1]}</span>;
        } else if (element[0] === "br") {
          return <br key={index} />;
        } else if (element[0] === "nbsp") {
          return <Fragment key={index}>&nbsp;</Fragment>;
        } else if (element.length === 2 && element[0].toLowerCase() === "li") {
          return <li key={index}>{element[1]}</li>;
        } else if (navigateFunction !== undefined && element.length === 3 && element[0].toLowerCase() === "navigate") {
          return <DHLActionTextLink key={index} action={() => navigateFunction(element[2])} text={element[1]} />;
        } else if (element.length > 1) {
          return (
              <a
                  key={index}
                  target={getTarget(element)}
                  href={element[1]}
              >
                {element[0]}
              </a>
          );
        } else {
          // unknown tag (between square brackets)
          return null;
        }
      } else {
        return element;
      }
    });
  }
  return text;
};