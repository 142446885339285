/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { ReactElement, useEffect, useRef, useState } from "react";
import {
  DHLCheckbox,
  DHLContextmenu,
  DHLContextmenuLabel,
  DHLIcon,
  DHLModal,
  DHLTableCellAllSelectableAccessors,
  ResourceDataStore,
  TableDataStore, TableRowType
} from "../../..";
import { genericObserver } from "../../../utils/genericObserver";
import { SelectionConfirmationDialogType } from "../../atoms/DHLTableCell/DHLTableCellBooleanAllSelectable";
import "./DHLTableHeaderAllSelectableBooleanCol.scss";
import { isResourceDataStoreTAdapter } from "../../../utils/i18next";
import { ResourceDataStoreTAdapter } from "../../../stores/ResourceDataStoreTAdapter";

export type DHLTableHeaderAllSelectableBooleanColProps<T extends {}> = {

  dataStore: TableDataStore<T>;

  colItem: ReactElement;

  disable?: boolean;
}
export const useBooleanState: (initialState: boolean) => { value: boolean, trueSetter: () => void, falseSetter: () => void, toggler: () => void }
    = (initialState: boolean) => {
  const [value, setValue] = useState(initialState);
  return {
    value,
    trueSetter: () => setValue(true),
    falseSetter: () => setValue(false),
    toggler: () => setValue(!value)
  };
};

function getContextMenuGlobalSelectAll<T>(allRowsAreOnSamePage: boolean, allSelected: boolean, resources: ResourceDataStore | ResourceDataStoreTAdapter, allSelectableRows: TableRowType<T>[], onGlobalSelectClicked: () => void) {
  return allRowsAreOnSamePage || allSelected
      ? null
      : <DHLContextmenuLabel
          checked={false}
          label={`${isResourceDataStoreTAdapter(resources)
              ? resources.t("globalSelectAll")
              : resources.getMsg("tables.contextmenu.globalSelectAll")} (${allSelectableRows.length})`}
          onClick={onGlobalSelectClicked}
      />;
}

function getContextMenuPageSelectAll<T>(allSelected: boolean, allOnCurrentPageSelected: boolean, resources: ResourceDataStore | ResourceDataStoreTAdapter, thisPageSelectableRows: T[], accessors: DHLTableCellAllSelectableAccessors<T>) {
  return allSelected || allOnCurrentPageSelected
      ? null
      : <DHLContextmenuLabel
          checked={false}
          label={isResourceDataStoreTAdapter(resources) ? resources.t("pageSelectAll")
              : resources.getMsg("tables.contextmenu.pageSelectAll") + " (" + thisPageSelectableRows.length + ")"}
          onClick={() => thisPageSelectableRows.forEach(row => accessors.setSelectedState(row, true))}
      />;
}

function getContextMenuPageDeselectAll<T>(noneSelected: boolean, noneOnCurrentPageSelected: boolean, resources: ResourceDataStore | ResourceDataStoreTAdapter, thisPageSelectableRows: T[], accessors: DHLTableCellAllSelectableAccessors<T>) {
  return noneSelected || noneOnCurrentPageSelected
      ? null
      : <DHLContextmenuLabel
          checked={false}
          label={isResourceDataStoreTAdapter(resources) ? resources.t("pageDeselectAll") : resources.getMsg("tables.contextmenu.pageDeselectAll")}
          onClick={() => thisPageSelectableRows.forEach(row => accessors.setSelectedState(row, false))}
          className="resetContextMenuOption"
      />;
}

function getContextMenuGlobalDeselectAll(allRowsAreOnSamePage: boolean, noneSelected: boolean, resources: ResourceDataStore | ResourceDataStoreTAdapter, onGlobalDeselectClicked: () => void) {
  return allRowsAreOnSamePage || noneSelected
      ? null
      : <DHLContextmenuLabel
          checked={false}
          label={isResourceDataStoreTAdapter(resources)
              ? resources.t("globalDeselectAll")
              : resources.getMsg("tables.contextmenu.globalDeselectAll")
          }
          onClick={onGlobalDeselectClicked}
          className="resetContextMenuOption"
      />;
}

export const DHLTableHeaderAllSelectableBooleanCol = genericObserver(
    function <T extends {}>({
                              dataStore, colItem, disable = false
                            }: DHLTableHeaderAllSelectableBooleanColProps<T>
    ) {
      const {value: contextMenuOpen, falseSetter: closeContextMenu, toggler: toggleContextMenu} = useBooleanState(false);
      const {
        value: confirmSelectDialogOpen,
        trueSetter: openConfirmSelectDialog,
        falseSetter: closeConfirmSelectDialog
      } = useBooleanState(false);
      const {
        value: confirmDeselectDialogOpen,
        trueSetter: openConfirmDeselectDialog,
        falseSetter: closeConfirmDeselectDialog
      } = useBooleanState(false);
      const resources = dataStore.baseDataStore.resourceDataStore;

      const headerCellRef = useRef<HTMLTableHeaderCellElement>(null);

      const props = colItem.props;
      const accessors = props.allSelectableAccessors as DHLTableCellAllSelectableAccessors<T>;
      const showDialogs = props.showConfirmationDialogs as SelectionConfirmationDialogType[];

      const allSelectableRows = dataStore.currentData.filter(row => accessors.isSelectable(row));
      const thisPageSelectableRows = dataStore.currentSlice.filter(row => accessors.isSelectable(row));
      const allRowsAreOnSamePage = dataStore.paginationDataStore.noOfPages === 1;
      const anythingSelectable = allSelectableRows.length > 0;

      const allSelected = allSelectableRows.length > 0 && allSelectableRows.every(row => accessors.isSelected(row));
      const someSelected = allSelectableRows.some(row => accessors.isSelected(row)) && !allSelected;
      const noneSelected = !someSelected && !allSelected;

      const onCurrentPageSelectable = dataStore.currentSlice.filter(row => accessors.isSelectable(row));
      const allOnCurrentPageSelected = onCurrentPageSelectable.length > 0
          && onCurrentPageSelectable.every(row => accessors.isSelected(row));
      const noneOnCurrentPageSelected = onCurrentPageSelectable.every(row => !accessors.isSelected(row));

      const confirmGlobalSelection = () => {
        allSelectableRows.forEach(row => accessors.setSelectedState(row, true));
        closeConfirmSelectDialog();
      };

      const confirmGlobalDeselection = () => {
        allSelectableRows.forEach(row => accessors.setSelectedState(row, false));
        closeConfirmDeselectDialog();
      };

      const onGlobalSelectClicked = () => {
        if (!showDialogs || !showDialogs.find(it => it === "selection")) {
          confirmGlobalSelection();
        } else {
          openConfirmSelectDialog();
        }
      };

      const onGlobalDeselectClicked = () => {
        if (!showDialogs || !showDialogs.find(it => it === "deselection")) {
          confirmGlobalDeselection();
        } else {
          openConfirmDeselectDialog();
        }
      };

      const disableClassName = disable ? "disable" : "";

      const checkboxTooltip = isResourceDataStoreTAdapter(resources) ? resources.t("noSelectionPossible")
          : resources.getTooltip("tables.contextmenu.noSelectionPossible");
      const inner = (
          <div className="dhlTableheaderAllSelectableBooleanCol-inner">
            <DHLCheckbox name={props.propertiesName}
                         value={allSelected}
                         indeterminate={someSelected}
                         disabled={!anythingSelectable || disable}
                         onChange={() => {/* nothing */
                         }}
                         tooltip={anythingSelectable || disable ? undefined : checkboxTooltip}
            />
            <DHLIcon icon="more-functions" name="" className={classNames("label-allSelect-icon", disableClassName)} />
            {(anythingSelectable && !disable) &&
                <div className="dhlTableheaderAllSelectableBooleanCol-anchorContextmenu">
                  <DHLContextmenu visible={contextMenuOpen}>
                    {getContextMenuPageSelectAll(allSelected, allOnCurrentPageSelected, resources, thisPageSelectableRows, accessors)}
                    {getContextMenuGlobalSelectAll(allRowsAreOnSamePage, allSelected, resources, allSelectableRows, onGlobalSelectClicked)}
                    {getContextMenuPageDeselectAll(noneSelected, noneOnCurrentPageSelected, resources, thisPageSelectableRows, accessors)}
                    {getContextMenuGlobalDeselectAll(allRowsAreOnSamePage, noneSelected, resources, onGlobalDeselectClicked)}
                  </DHLContextmenu>
                </div>}
          </div>);


      const createSelectActionDialog = () => {
        if (confirmSelectDialogOpen) {
          if (isResourceDataStoreTAdapter(resources)) {
            return <DHLModal
                show={true}
                title={resources.t("confirmSelectAll.title")}
                onClickCancel={closeConfirmSelectDialog}
                onClickOk={confirmGlobalSelection}
                content={resources.t("confirmSelectAll.content")}
                cancelButton={resources.t("no")}
                okButton={resources.t("yes")}
            />;
          } else {
            return <DHLModal
                show={true}
                title={resources.getLabel("tables.contextmenu.confirmSelectAll")}
                onClickCancel={closeConfirmSelectDialog}
                onClickOk={confirmGlobalSelection}
                content={resources.getMsg("tables.contextmenu.confirmSelectAll")}
                cancelButton={resources.getButton("tables.contextmenu.cancelSelectAll")}
                okButton={resources.getButton("tables.contextmenu.confirmSelectAll")}
            />;
          }
        } else if (confirmDeselectDialogOpen) {
          if (isResourceDataStoreTAdapter(resources)) {
            return <DHLModal
                show={true}
                title={resources.t("confirmDeselectAll.title")}
                onClickCancel={closeConfirmDeselectDialog}
                onClickOk={confirmGlobalDeselection}
                content={resources.t("confirmDeselectAll.title")}
                cancelButton={resources.t("no")}
                okButton={resources.t("yes")}
            />;
          } else {
            return <DHLModal
                show={true}
                title={resources.getLabel("tables.contextmenu.confirmResetAll")}
                onClickCancel={closeConfirmDeselectDialog}
                onClickOk={confirmGlobalDeselection}
                content={resources.getMsg("tables.contextmenu.confirmResetAll")}
                cancelButton={resources.getButton("tables.contextmenu.cancelResetAll")}
                okButton={resources.getButton("tables.contextmenu.confirmResetAll")}
            />;
          }
        } else {
          return null;
        }
      };

      useEffect(() => {
        const onClickOutside = (e: MouseEvent) => {
          if (headerCellRef.current && !headerCellRef.current.contains(e.target as Node)) {
            closeContextMenu();
          }
        };
        document.addEventListener("mousedown", onClickOutside);
        return () => {
          document.removeEventListener("mousedown", onClickOutside);
        };
      });

      return (<>
        <th
            ref={headerCellRef}
            onClickCapture={toggleContextMenu}
            className={classNames("dhlTableheaderCell", anythingSelectable && !disable ? "dhlTableheaderCell-interactable" : null)}
        >
          {inner}
        </th>
        {createSelectActionDialog()}
      </>);
    }
);