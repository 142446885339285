/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Dispatch, MouseEventHandler, ReactElement, SetStateAction } from "react";
import { uid } from "react-uid";
import {
  DHLTableColBoolean,
  DHLTableColButton,
  DHLTableColCurrency,
  DHLTableColCustom,
  DHLTableColDateTime,
  DHLTableColIconButton,
  DHLTableColLink,
  DHLTableColNumber,
  DHLTableColResourceKey,
  DHLTableColText
} from "../../..";
import "./DHLTableCol.scss";
import { DHLTableColIconButtonContextMenu } from "../../atoms/DHLTableColIconButtonContextMenu/DHLTableColIconButtonContextMenu";
import { DHLAnimatedChevron } from "../../atoms/DHLAnimatedChevron/DHLAnimatedChevron";

export type DHLTableColProps<T> = {

  /** Anzuzeigender Wert. */
  value: any;

  /** Called when a table row is selected. */
  selected: (event: React.MouseEvent) => void;

  /** die Gesamte Tabellenzeile */
  row: T;

  /** Formatierer für Spalte. */
  formatter?: any;

  /** Definition der Zelle. */
  cellDefinition: ReactElement;

  /** ID der Zelle */
  cellId: string;

  /** Name des Properties, das die ID enthält. */
  idPropertiesName: string;

  /** Deaktiviertheitsstatus */
  disabled?: boolean;

  isDetailViewCol?: boolean
  detailViewControl: {
    hasDetailView: boolean,
    isDetailViewOpen: boolean
    setDetailViewOpen: Dispatch<SetStateAction<boolean>>
  }
};

function getTabelCellClassName(alignmentClass: any, disableMinWidth: any, className: any, clickCursorClass: any, columnSpecificClasses: any) {
  return classNames("dhlTablecell",
      alignmentClass,
      disableMinWidth ? "noMinWidth" : null,
      className,
      clickCursorClass,
      columnSpecificClasses
  );
}

/** Ausgabe einer Tabellenzelle - Allgemein. */
export const DHLTableCol = observer(<T extends unknown>(
    {value, selected, formatter, cellDefinition, cellId, idPropertiesName, row, disabled, detailViewControl}: DHLTableColProps<T>) => {
  const {
    type, className, propertiesName, onChange, maxLength, customRenderer, resolve,
    contentAlignment, disableMinWidth, dateFormat, timeFormat, title
  } = cellDefinition.props;
  let output = value;
  let alignmentClass = null;
  let columnSpecificClasses = null;
  let onTdClick: MouseEventHandler<HTMLTableDataCellElement> = (event) => {
    selected(event);
    const {hasDetailView, isDetailViewOpen, setDetailViewOpen} = detailViewControl;
    if (hasDetailView) {
      setDetailViewOpen(!isDetailViewOpen);
    }
  };
  if (contentAlignment) {
    alignmentClass = "align-" + contentAlignment;
  }

  let clickhandlerToDisable = false;

  function disableSelectAndOpenCall() {
    clickhandlerToDisable = true;
  }

  if ("detailViewOpener" === type) {
    output = detailViewControl.hasDetailView ? <DHLAnimatedChevron active={detailViewControl.isDetailViewOpen}
                                                                   onClick={() => detailViewControl.setDetailViewOpen(s => !s)}
    /> : "";
    columnSpecificClasses = "fixedColumn-20px no-horizontal-padding";
  } else if (output === null) {
    output = "";
  } else if ("resourceKey" === type) {
    output = <DHLTableColResourceKey resolve={resolve} value={value} />;
  } else if ("custom" === type) {
    output = (
        <DHLTableColCustom
            key={"col-" + uid(cellId)}
            value={value}
            cellId={cellId}
            onChange={onChange}
            idPropertyName={idPropertiesName}
            colPropertyName={propertiesName}
            row={row as Object} // to do: introduce type T below
            customRenderer={customRenderer}
        />);
    if (onChange) {
      disableSelectAndOpenCall();
    }
  } else if ("boolean" === type) {
    output = (
        <DHLTableColBoolean
            key={"col-" + uid(cellId)}
            value={value}
            cellId={cellId}
            onChange={onChange}
            idPropertyName={idPropertiesName}
            colPropertyName={propertiesName}
            disabled={disabled}
        />
    );
    disableSelectAndOpenCall();
    columnSpecificClasses = !title ? "fixedColumn-20px" : null;
  } else if ("text" === type && typeof value !== "boolean") {
    output = (
        <DHLTableColText
            key={"col-" + uid(cellId)}
            value={value}
            cellId={cellId}
            onChange={onChange}
            maxLength={maxLength}
            idPropertyName={idPropertiesName}
            colPropertyName={propertiesName}
        />
    );
    if (onChange) {
      disableSelectAndOpenCall();
    }
  } else if ("number" === type) {
    output = <DHLTableColNumber key={"col-" + uid(cellId)} value={value} formatter={formatter} />;
  } else if ("currency" === type) {
    output = <DHLTableColCurrency key={"col-" + uid(cellId)} value={value} formatter={formatter} />;
  } else if ("dateTime" === type || "date" === type || "time" === type) {
    output = <DHLTableColDateTime key={"col-" + uid(cellId)} value={value} type={type} formatter={formatter} dateFormat={dateFormat}
                                  timeFormat={timeFormat} />;
  } else if ("iconButton" === type) {
    const {iconName, dynamicIconNameFunction, onClick, tooltip, dynamicTooltipFunction, disabledTooltip, isVisible} = cellDefinition.props;
    output = <DHLTableColIconButton name={cellId} iconName={iconName} dynamicIconNameFunction={dynamicIconNameFunction} onClick={onClick}
                                    value={value} disabled={disabled}
                                    disabledTooltip={disabledTooltip} tooltip={tooltip} dynamicTooltipFunction={dynamicTooltipFunction}
                                    isVisible={isVisible}
    />;
    columnSpecificClasses = !title ? "fixedColumn-20px no-horizontal-padding" : null;
    disableSelectAndOpenCall();
  } else if ("iconButtonWithContextMenu" === type) {
    const {
      iconName,
      dynamicIconNameFunction,
      onClick,
      onClickOutside,
      tooltip,
      dynamicTooltipFunction,
      disabledTooltip,
      contextMenuContent,
      contextMenuOpen,
      placement
    } = cellDefinition.props;
    output = <DHLTableColIconButtonContextMenu
        name={cellId}
        iconName={iconName}
        dynamicIconNameFunction={dynamicIconNameFunction}
        onClick={onClick}
        value={value}
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        tooltip={tooltip}
        dynamicTooltipFunction={dynamicTooltipFunction}
        contextMenuContent={contextMenuContent}
        contextMenuOpen={contextMenuOpen(value)}
        onClickOutside={onClickOutside}
        placement={placement} />;
    columnSpecificClasses = !title ? "fixedColumn-20px no-horizontal-padding" : null;
    disableSelectAndOpenCall();
  } else if ("button" === type) {
    const {label, size, buttonType, iconPosition, icon, onClick, tooltip, tooltipPlacement, disabledTooltip} = cellDefinition.props;
    output = <DHLTableColButton name={cellId} label={label} value={value} size={size} buttonType={buttonType} iconPosition={iconPosition}
                                icon={icon} onClick={onClick} tooltip={tooltip} tooltipPlacement={tooltipPlacement} disabled={disabled}
                                disabledTooltip={disabledTooltip} />;
    disableSelectAndOpenCall();
  } else if ("imageLink" === type || "buttonLink" === type || "link" === type) {
    const {onClick, label} = cellDefinition.props;
    output = (
        <DHLTableColLink
            label={label}
            cellId={cellId}
            key={"col-" + uid(cellId)}
            value={value}
            onClick={onClick}
            disabled={disabled}
        />
    );
    disableSelectAndOpenCall();
  } else if (typeof value === "boolean") {
    output = String(value);
  }

  let clickCursorClass = null;
  if (clickhandlerToDisable) {
    onTdClick = () => { /* intended use */
    };
  } else if (detailViewControl.hasDetailView) {
    clickCursorClass = "clickDVNotDis";
  }

  return <td className={getTabelCellClassName(alignmentClass, disableMinWidth, className, clickCursorClass, columnSpecificClasses)}
             onClick={onTdClick}>{output}</td>;
});