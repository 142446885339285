/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "@gkuis/gkp-authentication";

const TTL_MILLIS = 5 * 60 * 1000;

export type Subscriber = (newValueOrError: boolean | Error) => void

type FeatureToggleCacheEntry = {
  value: boolean,
  expires: Date
}

export interface FeatureToggleProvider {
  getIfPresent(featureToggleName: string): boolean | undefined;

  subscribe(featureToggleName: string, subscriber: Subscriber): void;

  unsubscribe(featureToggleName: string, subscriber: Subscriber): void;
}

export class FeatureToggleProviderImpl implements FeatureToggleProvider {
  private readonly cache: Partial<Record<string, FeatureToggleCacheEntry>> = {};
  private readonly locks = new Set<string>;
  private readonly subscribers: Partial<Record<string, Set<Subscriber>>> = {};

  constructor(private baseUrl: string) {}

  public getIfPresent(featureToggleName: string): boolean | undefined {
    return this.cache[featureToggleName]?.value;
  }

  public subscribe(featureToggleName: string, subscriber: Subscriber) {
    this.subscribers[featureToggleName] = this.subscribers[featureToggleName] ?? new Set();
    this.subscribers[featureToggleName].add(subscriber);

    if (this.cache[featureToggleName] !== undefined) {
      subscriber(this.cache[featureToggleName].value);
    } else {
      void this.fetchAndCache(featureToggleName);
    }
  }

  public unsubscribe(featureToggleName: string, subscriber: Subscriber) {
    this.subscribers[featureToggleName]?.delete(subscriber);
  }

  private async fetchAndCache(featureToggleName: string): Promise<void> {
    if (this.cache[featureToggleName] !== undefined && this.cache[featureToggleName].expires > new Date()) {
      logger.log("Key", featureToggleName, "already cached and still valid with value", this.cache[featureToggleName], ". Skipping.");
      return;
    }

    if (this.locks.has(featureToggleName)) {
      // wait for lock to be released
      while (this.locks.has(featureToggleName)) {
        await new Promise<void>(resolve => setTimeout(resolve, 50));
      }
      return await this.fetchAndCache(featureToggleName);
    }

    try {
      this.locks.add(featureToggleName);

      const response = await fetch(`${this.baseUrl}/v1/featuretoggle/${featureToggleName}`);
      if (!response.ok) {
        // noinspection ExceptionCaughtLocallyJS this is intended
        throw new Error(`Unexpected response status: ${response.status} ${response.statusText}`);
      }
      const value = await response.json();
      if (typeof value !== "boolean") {
        // noinspection ExceptionCaughtLocallyJS this is intended
        throw new Error(`Unexpected response json: ${value}`);
      }

      this.cache[featureToggleName] = {value, expires: new Date(Date.now() + TTL_MILLIS)};
      this.subscribers[featureToggleName]?.forEach(subscriber => subscriber(value));
      setTimeout(
          () => {
            if (this.subscribers[featureToggleName] === undefined || this.subscribers[featureToggleName].size === 0) {
              delete this.cache[featureToggleName];
            } else {
              void this.fetchAndCache(featureToggleName);
            }
          },
          TTL_MILLIS
      );
    } catch (e) {
      logger.error("Fetching feature toggle", featureToggleName, "failed:", e);
      delete this.cache[featureToggleName];
      this.subscribers[featureToggleName]
          ?.forEach(subscriber => subscriber(new Error(`Fetching feature toggle ${featureToggleName} failed`, {cause: e})));
    } finally {
      this.locks.delete(featureToggleName);
    }
  }
}

let _featureToggleProvider: FeatureToggleProvider | undefined = undefined;

export function setFeatureToggleProvider(featureToggleProvider: FeatureToggleProvider) {
  _featureToggleProvider = featureToggleProvider;
}

export function getFeatureToggleProvider(): FeatureToggleProvider {
  if (_featureToggleProvider === undefined) {
    throw new Error("No FeatureToggleProvider configured.");
  }

  return _featureToggleProvider;
}
