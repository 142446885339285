/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Collapse } from "reactstrap";
import { DHLHeadline, DHLIcon, DHLTextOutput } from "../../..";
import { createMessageWithSpecialTags } from "../../../utils/messagesUtils";
import "./DHLPagetemplate.scss";

export type DHLPagetemplateProps = {
  /** Name für test-id. */
  name?: string,

  /** Top left page template items. */
  topLeftItems?: ReactNode,

  /** Buttons to show in the top right corner of the page.*/
  headerButtons?: ReactNode,

  /** Headline (Vorgabe: h1, Design h4) */
  headlineText?: string,

  /** Subline / Beschreibungstext*/
  sublineText?: string,

  /** if headline and subline given, should be subline collapsible (and initial collapsed) */
  collapsibleSubline?: boolean,

  /** text for link in subline to hide it */
  sublineHideText?: string,

  /** if subline should be shown initially if collapsible*/
  showSublineInitially?: boolean,

  /** DHL Message Notification (Erfolg oder Fehler) die im Page Intro Header angezeigt werden soll */
  dhlMessage?: ReactNode,

  /** DHL Progress bar die im Page Intro Header angezeigt werden soll */
  progressBar?: ReactNode,

  /** Der eigentliche Content der Seite. Wird als ReactNode Children übergeben. */
  children: ReactNode,

  /** Buttons am Ende der Seite (Vorgabe: btn-default sm, ggf. btn-group) */
  endOfPageActions?: ReactNode,

  /** Optional style classes which will be added on the outer most level. */
  className?: string,
};

/** Template for page structure. */
export const DHLPagetemplate = (
    {
      name,
      topLeftItems,
      headerButtons,
      headlineText,
      sublineText,
      collapsibleSubline = false,
      sublineHideText,
      showSublineInitially = false,
      dhlMessage,
      progressBar,
      children,
      endOfPageActions,
      className
    }: DHLPagetemplateProps
) => {
  const canSublineBeCollapsed = headlineText && sublineText && collapsibleSubline;

  // initial collapse despite useState(false)
  const [sublineExpanded, setSublineExpanded] = useState(showSublineInitially);
  const collapseButton = <DHLIcon
      name={name + "-sublineCollapse"}
      icon="info-grey"
      className={classNames("info-icon", {active: sublineExpanded})}
      onClick={() => setSublineExpanded(!sublineExpanded)}
  />;

  let sublineElement = null;
  if (sublineText) {
    const textAny = createMessageWithSpecialTags(sublineText);
    let closeLink = null;
    if (sublineHideText) {
      closeLink = <>
        {" "}
        <span
            className="hideSublineLink"
            onClick={() => setSublineExpanded(false)}
            onKeyDown={() => setSublineExpanded(false)}
            role={"button"}
            tabIndex={0} //sonar...
        >
          {sublineHideText}
        </span>
      </>;
    }
    let sublineContent = <DHLTextOutput name={name + "-sublineIn"} textAlignment={"center"} value={<>{textAny}{closeLink}</>} />;
    if (canSublineBeCollapsed) {
      // dhltransition-default
      sublineContent = <Collapse isOpen={sublineExpanded} timeout={250 /* dhltransition-default */}>{sublineContent}</Collapse>;
    }
    sublineElement = <div className={classNames("col-8", "offset-2", "sublineText",
        (canSublineBeCollapsed) && "dhlCollapsible",
        (canSublineBeCollapsed && !sublineExpanded) && "dhlCollapsed"
    )}
    >
      {sublineContent}
    </div>;
  }

  return <div data-testid={name} className={classNames(className, "dhlPagetemplate", "dhlGridContainer", "container")}>
    {(headlineText || sublineElement || progressBar || dhlMessage || topLeftItems || headerButtons) &&
        <div className={classNames("pageIntroHeader", "dhlGridRow", "row")}>
          {headlineText &&
              <div className={classNames("col-8", "offset-2", "headlineText")}>
                <DHLHeadline type={"h1"} visualType={"h4"}
                             textAlignment="center">{createMessageWithSpecialTags(headlineText)}{canSublineBeCollapsed &&
                    collapseButton}</DHLHeadline>
              </div>
          }
          {sublineElement}
          {progressBar}
          { /* Red Herring: dhlMessage can be truthy but still render out to an empty DOM, thus div be empty but existing */
              dhlMessage &&
              <div className={classNames("col-8", "offset-2", "dhlMessage")}>
                {dhlMessage}
              </div>
          }
          {topLeftItems &&
              <div className={classNames("topLeftItems")}>
                {topLeftItems}
              </div>
          }
          {headerButtons &&
              <div className={classNames("headerButton")}>
                {headerButtons}
              </div>
          }
        </div>
    }
    <div className={classNames("pageContent", "row")}>
      <div className={classNames("col-12", "pageContentContainer")}>
        {children}
      </div>
    </div>
    {endOfPageActions &&
        <div className={classNames("endOfPageActions", "row")}>
          <div className={classNames("col-12", "endOfPageActionsContainer")}>
            {endOfPageActions}
          </div>
        </div>}
  </div>;
};