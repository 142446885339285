/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { MouseEvent, ReactElement, ReactNode, useEffect, useState } from "react";
import { createMessageWithSpecialTags, DHLButton, DHLButtonGroup, DHLTextOutput, formatNumber, useLocaleManager } from "../../..";
import { TableDataStore } from "../../../stores/TableDataStore";
import { FormatterMappingType, TableRowSelectType } from "../../../types/TableDataStoreTypes";
import { formatterCurrency, formatterDate, formatterDateTime, formatterNumber, formatterTime } from "../../../utils/formatter";
import { genericObserver } from "../../../utils/genericObserver";
import { logger } from "../../../utils/logger";
import { DHLPageSizeSelector } from "../../molecules/DHLPageSizeSelector/DHLPageSizeSelector";
import { DHLPagination } from "../../molecules/DHLPagination/DHLPagination";
import { DHLTableBody } from "../../molecules/DHLTableBody/DHLTableBody";
import { DHLTableHeaderRow } from "../../molecules/DHLTableHeaderRow/DHLTableHeaderRow";
import "./DHLTable.scss";
import { DHLDataFilterProps } from "../../molecules/DHLDataFilter/DHLDataFilter";
import { isResourceDataStoreTAdapter } from "../../../utils/i18next";
import { useTranslation } from "react-i18next";

type Position = "top" | "bottom" | "both" | "none";

export type DHLTableProps<T extends {}> = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Beschreibung der Tabelle. */
  description?: string;

  /** Tabellen Datenspeicher. */
  dataStore: TableDataStore<T>;

  /**
   * data filter display component
   */
  dataFilter?: ReactElement<DHLDataFilterProps<T>>;

  /** Function used to identify (a) row(s) to be highlighted based on data the row provides */
  highlightedRows?: (data: object) => boolean;

  /** Function used to identify (a) row(s) to be disabled based on data the row provided */
  disabledRows?: (data: object) => boolean;

  /** Function used to identify (a) row(s) which have a detail view */
  hasDetailViewFunc?: (data: T) => boolean;

  /** Ausgabe bei leeren Daten. */
  noDataText?: string | ReactElement;

  /** Ausgabe bei leeren Daten verursacht durch einen zu restriktiven Filter. */
  noDataDueToFilterText?: string | ReactElement;

  /** Text der während des Ladevorgangs angezeigt wird. */
  loadingText?: string;

  /** Name des Properties, das die ID enthält. */
  idPropertiesName?: string;

  /** Gibt an, ob und wo ein Pager platziert werden soll. Deaktiviert nicht Pagination selbst! **/
  pager?: Position;

  /** Label für den unteren Pager. */
  bottomPagerLabel?: string;

  /** Wird bei Selektion einer Tabellenzeile aufgerufen, die Daten der aktuellen Zeile werden an die Funktion übergeben. */
  selected?: TableRowSelectType<T>;

  /** Soll der Tabellenheader sticky sein? */
  stickyHeader?: boolean;

  /** If set replaces the default dataStatus indicator singular with the specified text */
  dataStatusIndicatorSingular?: string;

  /** If set replaces the default dataStatus indicator plural with the specified text */
  dataStatusIndicatorPlural?: string;

  /** Informationen zur Treffermenge ausgeben. */
  showDataStatusIndicator?: boolean;

  /** A row related to the table in which summary information can be displayed.  */
  summaryContent?: ReactNode;

  /** Actionbar settings, only if given, actionbar will be displayed */
  actionbarSettings?: ActionbarProps,

  /** Secondary Actionbar - Primary actions (Vorgabe btn-primary sm) */
  actionbarSecondLevelPrimary?: ReactNode,

  /** Secondary Actionbar - Secondary actions (Vorgabe btn-default sm) */
  actionbarSecondLevelSecondary?: ReactNode,

  /** Kind-Komponenten. */
  children: ReactElement[] | ReactElement;

  /** Simple sorting mode without context menu */
  complexSortingEnabled?: boolean;

  /** Disable everything except sorting and paging*/
  disabled?: boolean
};

type ActionbarProps = {
  selectedItemsLocalizationKey?: string,
  visibleSelectedItemsLocalizationKey?: string,
  selectedItemsPropertyName?: string
  buttons?: ReactNode,
  resetSelectionText?: string,
  onResetSelection?: () => void,
}

/** Tabelle.
 *
 * Jedes Property einer Zeile darf nur ein Mal dargestellt werden (Wert der Eigenschaft "propertiesName" der einzelnen Spalten muss eindeutig sein)!
 */
export const DHLTable = genericObserver(function <T extends {}>(
    {
      name,
      description,
      dataStore,
      dataFilter,
      highlightedRows,
      disabledRows,
      hasDetailViewFunc,
      noDataText,
      noDataDueToFilterText,
      loadingText,
      idPropertiesName = "id",
      pager,
      bottomPagerLabel,
      selected,
      children,
      dataStatusIndicatorSingular,
      dataStatusIndicatorPlural,
      showDataStatusIndicator = false,
      stickyHeader,
      summaryContent,
      actionbarSettings,
      actionbarSecondLevelPrimary,
      actionbarSecondLevelSecondary,
      complexSortingEnabled = false,
      disabled = false
    }: DHLTableProps<T>
) {
  const localeManager = useLocaleManager();
  const {t} = useTranslation();
  /** Formatierer beim Komponentenaufbau intialisieren. */
  const [formatters, setFormatters] = useState<FormatterMappingType>(new Map());
  useEffect(() => {
    /**
     * Initialisiert den Formatierer für eine Zelle
     * @param formatterMapping Mapping aller Formatierer
     * @param cellDef Zellendefinition
     */
    const initFormatterForCell = (formatterMapping: FormatterMappingType, cellDef: any) => {
      const {
        type,
        propertiesName,
        minFractionDigits,
        maxFractionDigits,
        currencyType,
        groupingEnabled,
        displaySymbol,
        dateFormat,
        timeFormat
      } = cellDef;
      if ("number" === type) {
        formatterMapping.set(propertiesName, formatterNumber(localeManager, minFractionDigits, maxFractionDigits, groupingEnabled));
      } else if ("currency" === type) {
        formatterMapping.set(propertiesName, formatterCurrency(localeManager, currencyType, groupingEnabled, displaySymbol));
      } else if ("dateTime" === type) {
        formatterMapping.set(propertiesName, formatterDateTime(dateFormat, timeFormat));
      } else if ("date" === type) {
        formatterMapping.set(propertiesName, formatterDate(dateFormat));
      } else if ("time" === type) {
        formatterMapping.set(propertiesName, formatterTime(timeFormat));
      }
    };

    const formatterMappings: FormatterMappingType = new Map();
    (Array.isArray(children) ? children : [children])
        .map(colItem => colItem.props)
        .forEach(colItemProps => initFormatterForCell(formatters, colItemProps));
    setFormatters(formatterMappings);
  }, []);

  noDataText = noDataText ?? t("table.noData");
  noDataDueToFilterText = noDataDueToFilterText ?? t("table.noDataDueToFilter");

  const _renderPager = (pagerName: string) => {
    const paginationDataStore = dataStore.paginationDataStore;
    const moreThanOnePage = (paginationDataStore.noOfPages > 1);
    const pageSizeSelectorWanted = (paginationDataStore.noOfEntries > 5);

    // Navigationbar nicht darstellen, wenn unnötig
    if (!moreThanOnePage && !pageSizeSelectorWanted) {
      return null;
    }
    return <div className="dhlTable-navigationbar">
      <div>{/*intentionally empty, left side flex item*/}</div>
      <div><DHLPagination name={pagerName} dataStore={paginationDataStore} /></div>
      <div>{pageSizeSelectorWanted &&
          <DHLPageSizeSelector
              name={pagerName}
              dataStore={paginationDataStore}
              label={bottomPagerLabel}
          />}</div>
    </div>;
  };

  const renderPagerTop = () => {
    if (dataStore.paginationDataStore && ("top" === pager || "both" === pager)) {
      return _renderPager(name + "-pagertop");
    }
    return null;
  };

  const renderPagerBottom = () => {
    if (dataStore.paginationDataStore && ("bottom" === pager || "both" === pager)) {
      return _renderPager(name + "-pagerbottom");
    }
    return null;
  };

  const summeryRow = (summaryContent)
      ?
      <div className="dhlTable-summaryrow">
        {summaryContent}
      </div>
      : null;

  const _actionbarSecondLevel = (actionbarSecondLevelPrimary || actionbarSecondLevelSecondary) ?
      <div className="dhlTable-actionbar-second-level">
        <div><DHLButtonGroup>{actionbarSecondLevelSecondary}</DHLButtonGroup></div>
        <div><DHLButtonGroup>{actionbarSecondLevelPrimary}</DHLButtonGroup></div>
      </div>
      : null;

  const renderDataStatusIndicator = () => {
    if (showDataStatusIndicator) {
      let message;
      if (dataStore.loadingState === "LOADING") {
        message = loadingText;
      } else {
        const formattedRowCount = formatNumber(localeManager, dataStore.currentData.length, 0, 0, true);
        message = isResourceDataStoreTAdapter(dataStore.baseDataStore.resourceDataStore)
            ? dataStore.baseDataStore.resourceDataStore.t("table.youAreShownXLines", {count: dataStore.currentData.length})
            : (dataStore.currentData.length === 1)
                ? dataStatusIndicatorSingular ?? dataStore.baseDataStore.resourceDataStore.getMsg("dataStatusIndicator.rowCount.singular")
                : dataStatusIndicatorPlural ?? dataStore.baseDataStore.resourceDataStore.getMsg("dataStatusIndicator.rowCount.plural",
                [formattedRowCount]);
      }
      return (
          <div className={"data-statusindicator"}>
            {message}
          </div>
      );
    }
    return null;
  };

  const getSelectedItemsAllText = () => {
    if (actionbarSettings?.selectedItemsLocalizationKey === undefined) {
      return "";
    }
    const text = isResourceDataStoreTAdapter(dataStore.baseDataStore.resourceDataStore)
        ? dataStore.baseDataStore.resourceDataStore.t(
            "label." + actionbarSettings.selectedItemsLocalizationKey,
            {0: dataStore.getSelectedCountAll(actionbarSettings.selectedItemsPropertyName!).toString()}
        )
        : dataStore.baseDataStore.resourceDataStore.getLabel(
            actionbarSettings.selectedItemsLocalizationKey,
            [dataStore.getSelectedCountAll(actionbarSettings.selectedItemsPropertyName!).toString()]
        );
    return `[b|${text}]`;
  };

  const getSelecteItemsTextBR = () => {
    return actionbarSettings!.selectedItemsLocalizationKey && actionbarSettings!.visibleSelectedItemsLocalizationKey
        ? "[br]"
        : "";
  };

  const getSelectedItemsVisibleText = () => {
    if (actionbarSettings?.visibleSelectedItemsLocalizationKey === undefined) {
      return "";
    }
    return isResourceDataStoreTAdapter(dataStore.baseDataStore.resourceDataStore)
        ? dataStore.baseDataStore.resourceDataStore.t(
            "label." + actionbarSettings.visibleSelectedItemsLocalizationKey,
            {0: dataStore.getSelectedCountAll(actionbarSettings.selectedItemsPropertyName!).toString()}
        )
        : dataStore.baseDataStore.resourceDataStore.getLabel(
            actionbarSettings.visibleSelectedItemsLocalizationKey,
            [dataStore.getSelectedCountAll(actionbarSettings.selectedItemsPropertyName!).toString()]
        );
  };

  const getSelectedItemsText = (): string | undefined => {
    return actionbarSettings && actionbarSettings.selectedItemsPropertyName
        ? getSelectedItemsAllText() + getSelecteItemsTextBR() + getSelectedItemsVisibleText()
        : undefined;
  };

  logger.log("DHLTable", formatters);
  return (
      <div className="dhlTable-container">
        {dataFilter}
        {renderDataStatusIndicator()}
        {renderPagerTop()}
        <div className="">
          <table data-testid={name} className="dhlTable">
            {description ? <caption>{description}</caption> : null}
            <DHLTableHeaderRow
                name={name + "-h"}
                dataStore={dataStore}
                colItems={children}
                sticky={stickyHeader}
                complexSortingEnabled={complexSortingEnabled}
                disabled={disabled}
            />
            <DHLTableBody
                name={name + "-b"}
                data={dataStore.currentSlice}
                dataEmpty={dataStore.completeData.length === 0 ? noDataText : noDataDueToFilterText}
                idPropertiesName={idPropertiesName}
                cellDefs={children}
                formatter={formatters}
                loadingState={dataStore.loadingState}
                loadingText={loadingText}
                selected={selected !== undefined ? (event: MouseEvent, rowData) => selected(event, rowData) : undefined}
                highlightRows={highlightedRows}
                disabledRows={disabledRows}
                hasDetailViewFunc={hasDetailViewFunc}
            />
          </table>
        </div>
        {actionbarSettings
            && <ActionBar
                {...actionbarSettings}
                dataTestid={name + "-actionbar"}
                selectedItemsText={getSelectedItemsText()}
            />
        }
        {renderPagerBottom()}
        {summeryRow}
        {_actionbarSecondLevel}
      </div>
  );
});

const ActionBar = observer((props: ActionbarProps & { dataTestid: string, selectedItemsText?: string }) => {
      return <div className={"dhlTable-actionbar"}>
        {props.selectedItemsText
            && <DHLTextOutput
                value={createMessageWithSpecialTags(props.selectedItemsText)}
                className={"dhlTable-actionbar-selectedText"}
            />
        }
        {props.buttons && <div>{props.buttons}</div>}
        {props.resetSelectionText
            && <DHLButton
                name={props.dataTestid + "-resetSeleciton"}
                onClick={() => props.onResetSelection && props.onResetSelection()}
                label={props.resetSelectionText}
                type="ghost"
                size="xs"
                iconPosition="icon-first"
                icon="reload"
            />
        }
      </div>;
    }
);
