import classNames from "classnames";
import { ReactNode } from "react";
import { ButtonGroup } from "reactstrap";
import "./DHLButtonGroup.scss";

export type DHLButtonGroupProps = {
  /** CSS Klassen. */
  className?: string;

  /** Buttongröße (sm, lg) */
  size?: "sm" | "lg";

  /** Button vertikal anordnen. */
  vertical?: boolean;

  alignment?: "left" | "right" | "center";

  /** Element ausgeben? */
  render?: boolean;

  /** Kind-Komponenten. */
  children: ReactNode;
};

/** Button-Group. Erlaubt die horizontale oder vertikale Gruppierung mehrerer Buttons. */
export const DHLButtonGroup = ({className, size = "sm", vertical, alignment, render = true, children}: DHLButtonGroupProps) => {
  if (!render) {
    return null;
  }

  const alignmentClass = alignment === "center" ? "dhlButtonGroup-center" : alignment ? "float-" + alignment : null;

  return (
      <ButtonGroup
          className={classNames("dhlButtonGroup", alignmentClass, className)}
          size={size}
          vertical={vertical}
      >
        {children}
      </ButtonGroup>
  );
};
