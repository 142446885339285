/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import {observer} from "mobx-react-lite";
import { useState } from "react";
import * as Popper from "popper.js";
import {useUID} from "react-uid";
import { DHLLabel, FormField, logger } from "../../..";
import {ValidationRuleType} from "../../../types/ValidationRuleTypes";
import {
  calculateDisabled,
  calculateError,
  calculateErrorMarkerOnly,
  calculateHint,
  calculateLabel,
  calculateName,
  calculateRadioOnChange,
  calculateRequired,
  calculateSource,
  calculateValidationRule,
  calculateValue
} from "../../../utils/calcPropertiesValue";
import {DHLFieldNote} from "../../atoms/DHLFieldNote/DHLFieldNote";
import {DHLTooltip} from "../DHLTooltip/DHLTooltip";
import "./DHLRadioButton.scss";

export type DHLRadioButtonProps = {

  /** Name of the InputField. Should match the name of the property for the input value in order to use a single change handler. We also use this to
   *  generate the test ID.*/
  name?: string;

  /** Label text. */
  label?: string;

  /** CSS-Classes for the Label. */
  labelClassName?: string;

  /** CSS-Classes for the InputField. */
  className?: string;

  /** Add title with mandatory field marker? */
  required?: boolean;

  /** Editing mode for the Formular (true = Create New ('Neuanlage'), false = Editing ('Bearbeiten')). The mandatory field status of the
   *  InputField can depends on editing mode.*/
  createMode?: boolean;

  /** disabled? */
  disabled?: boolean;

  /** Output Element? */
  render?: boolean;

  /** Input value (optional so that Storybook etc. Allow inputs). */
  value?: any;

  /** Selection of the radio button returns this value. */
  selectValue: string;

  /** Radiobutton can be deselected. */
  isDeselectable?: boolean;

  /** Function for onChange-Calls. */
  onChange?: Function;

  /** Validation rules with configuration data for the InputField. */
  validationRule?: ValidationRuleType;

  /** Error text(s), when one or more errors occurred. */
  error?: string | string[] | null;

  /** Mark only the input field or also show error texts? */
  errorMarkerOnly?: boolean;

  /** Information text(s), when one or more errors occurred.*/
  hint?: string | string[] | null;

  /** Source reference(s), when one or more sources are given. */
  source?: string | string[] | null;

  /** default or slim size. */
  size?: "default" | "slim";

  /** Defines a form field with name, Label, Value, validation rule and error text. Individual parameter have priority.*/
  formField?: FormField<any>;

  /** Tooltip. */
  tooltip?: string | React.ReactChild;

  /**Tooltip alignment */
  tooltipPlacement?: Popper.Placement;

  /**Tooltip, when inputField disabled */
  disabledTooltip?: string | React.ReactChild;
};

function getCalcErrorClass(calcError: string | string[] | null | undefined) {
  return calcError !== null && calcError !== undefined && calcError.length > 0 ? "error" : "";
}

/** RadioButton with integrated Label. */
export const DHLRadioButton = observer(
    ({
       name,
       label,
       labelClassName,
       className,
       required,
       createMode,
       disabled,
       render = true,
       value,
       selectValue,
       isDeselectable = false,
       onChange,
       validationRule,
       error,
       errorMarkerOnly,
       hint,
       source,
       size = "default",
       formField,
       tooltip,
       disabledTooltip,
       tooltipPlacement = "bottom",
     }: DHLRadioButtonProps) => {

      const [tooltipOpen, setTooltipOpen] = useState(false);

      if (!render) {
        return null;
      }

      const toggle = () => setTooltipOpen(!tooltipOpen);

      let usedTooltip = tooltip;

      if (disabled && disabledTooltip) {
        usedTooltip = disabledTooltip;
      }

      const calcName = calculateName(name, formField);

      if (!calcName) {
        logger.error("Components needs an explicit name or formField parameter");
        return null;
      }

      const calcValidationRule = calculateValidationRule(validationRule, formField);
      const calcLabel = calculateLabel(label, formField);
      const calcError = calculateError(error, formField);
      const calcHint = calculateHint(hint, formField);
      const calcSource = calculateSource(source, formField);
      const calcValue = calculateValue(value, formField);
      const isRadioButtonSelected = selectValue === calcValue;
      const calcOnChangeValue = isRadioButtonSelected ? "" : selectValue;
      const calcOnChange = calculateRadioOnChange(onChange, calcOnChangeValue, formField);
      const calcRequired = calculateRequired(required, calcValidationRule, createMode);
      const calcErrorMarkerOnly = calculateErrorMarkerOnly(errorMarkerOnly, calcValidationRule);
      const calcDisabled = calculateDisabled(disabled, calcValidationRule, createMode);
      const disabledOrNull = calcDisabled ? "disabled" : null;
      const calcErrorClass = getCalcErrorClass(calcError);

      const calcOnClick = (calcDisabled || (isRadioButtonSelected && !isDeselectable)) ? () => { /* intended use */ } : calcOnChange;

      const uid = "i" + useUID(); // id must not start with digit because used tooltip library restriction

      const output = (
        <div data-testid={calcName} className={classNames(className, "radiobutton-container", "form-radiobutton")}>
          <div className={classNames("radiobutton-and-label-container", "form-radiobutton", disabledOrNull)}>
            <div id={uid} data-testid={`${calcName}-radiobutton`}
                 className={classNames("radiobutton", disabledOrNull, isRadioButtonSelected ? "checked"
                   : "unchecked", calcErrorClass, size)}
                 onClick={calcOnClick} />
            {calcLabel && (
              <DHLLabel
                name={`${calcName}-label`}
                htmlFor={uid}
                label={calcLabel}
                required={calcRequired}
                checkbox={true}
                onClick={calcOnClick}
                className={classNames("form-radio-label", labelClassName)}
              />
            )}
            {usedTooltip &&
              <DHLTooltip
                  testid={`${calcName}-tooltip`}
                  placement={tooltipPlacement}
                  tooltipOpen={tooltipOpen}
                  target={uid}
                  toggle={toggle}>
                {usedTooltip}
              </DHLTooltip>}
            </div>
          {!calcErrorMarkerOnly && calcError && <DHLFieldNote name={`${calcName}-error`} type={"error"} classname={size} notes={calcError} />}
          {calcHint && <DHLFieldNote name={`${calcName}-hint`} type={"hint"} classname={size} notes={calcHint} />}
          {calcSource && <DHLFieldNote name={`${calcName}-source`} type={"source"} classname={size} notes={calcSource} />}
        </div>
      );

      return <>{output}</>;
    }
);
