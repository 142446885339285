/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import * as Popper from "popper.js";
import { DHLButton } from "../../..";
import { normalizeName } from "../../../utils/stringUtils";
import { IconType } from "../DHLIcon/DHLIcon";

export type DHLTableColIconButtonProps = {

  /** ID der Zelle. */
  name: string;

  /** Wert für Übergabe an onClick. */
  value: any;

  /** Name of the icon to use. */
  iconName?: IconType;

  /** Function for dynamic computation of icon to use. */
  dynamicIconNameFunction?: (value: any) => IconType;

  /** Funktion wird bei Klick auf das Icon aufgerufen. */
  onClick: (event: React.MouseEvent, value: any) => void;

  /** Tooltiptext. */
  tooltip?: string | React.ReactNode;

  /** Function for dynamic computation of tooltip text. */
  dynamicTooltipFunction?: (value: any) => string | React.ReactNode;

  /** Ausrichtung des Tooltips. */
  tooltipPlacement?: Popper.Placement;

  /** Tooltip für deaktivierte Links, Buttons. */
  disabledTooltip?: string;

  /** Deaktiviertheitsstatus */
  disabled?: boolean;

  /** If true cell is displayed */
  isVisible?: (value: any) => boolean;
};

/** Ausgabe einer Tabebellenzelle - Icon (Edit, Delete, ..). */
export const DHLTableColIconButton = observer(({
                                                 name,
                                                 iconName,
                                                 dynamicIconNameFunction,
                                                 onClick,
                                                 value,
                                                 tooltip,
                                                 dynamicTooltipFunction,
                                                 disabledTooltip,
                                                 disabled,
                                                 isVisible
                                               }: DHLTableColIconButtonProps) => {
  const onIconButtonClick = (evt: React.MouseEvent): void => {
    evt.preventDefault();

    onClick(evt, value);
  };
  const normalizedName = normalizeName(name);
  const calcIconName = dynamicIconNameFunction ? dynamicIconNameFunction(value) : iconName;
  const calcTooltip = dynamicTooltipFunction ? dynamicTooltipFunction(value) : tooltip;

  if (isVisible !== undefined && !isVisible(value)) {
    return <></>;
  }

  return (
      <DHLButton
          name={normalizedName}
          iconPosition={"icon"}
          icon={calcIconName}
          onClick={onIconButtonClick}
          tooltip={calcTooltip}
          disabledTooltip={disabledTooltip}
          disabled={disabled} />
  );

});
