/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, IObservableArray, makeObservable, observable, ObservableMap } from "mobx";
import { logger } from "../utils/logger";

export type MessageSeverity =  "success" | "error" | "neutral" | "warning";
export type MessageDurability =  "permanent" | "temporary";

export type MessageDataStoreMessage = {
  message: string,
  severity: MessageSeverity,
  durability: MessageDurability
}

const LOG_MODULE = "[MessageDataStore] ";

/** Data store for messages. */
export class MessageDataStore {
  private readonly _allMsg: IObservableArray<MessageDataStoreMessage>
  private readonly _allHeadlines: ObservableMap<MessageSeverity, string>

  /** Constructor. */
  constructor() {
    this._allMsg = observable.array([]);
    this._allHeadlines = observable.map([]);
    
    makeObservable<MessageDataStore>
    (this, {
      successMsgs: computed,
      successHeadline: computed,
      permanentSuccessMsgs: computed,
      errorMsgs: computed,
      errorHeadline: computed,
      permanentErrorMsgs: computed,
      neutralMsgs: computed,
      neutralHeadline: computed,
      permanentNeutralMsgs: computed,
      warningMsgs: computed,
      warningHeadline: computed,
      permanentWarningMsgs: computed,
      allErrorMsg: computed,
      allSuccessMsg: computed,
      allNeutralMsg: computed,
      allWarningMsg: computed,
      clearTemporaryMessages: action,
      clearErrorMessages: action,
      clearSuccessMessages: action,
      clearNeutralMessages: action,
      clearWarningMessages: action,
      clearPermanentMessages: action,
      addSuccessMessage: action,
      addErrorMessage: action,
      addNeutralMessage: action,
      addWarningMessage: action,
      addPermanentSuccessMessage: action,
      addPermanentErrorMessage: action,
      addPermanentNeutralMessage: action,
      addPermanentWarningMessage: action,
      clear: action,
      successMsgCount: computed,
      errorMsgCount: computed,
      neutralMsgCount: computed,
      warningMsgCount: computed
    });
  }

  getMsgs(): MessageDataStoreMessage[]{
    return this._allMsg.slice();
  }

  addMessage(msg: MessageDataStoreMessage){
    this._allMsg.push(msg);
  }

  getMsgsBySeverityAndDurability(severity: MessageSeverity, durability: MessageDurability): MessageDataStoreMessage[]{
    return this._allMsg.filter(m => m.severity === severity && m.durability ===  durability);
  }

  getMsgsBySeverity(severity: MessageSeverity): MessageDataStoreMessage[]{
    return this._allMsg.filter(m => m.severity === severity);
  }

  getMsgsByDurability(durability: MessageDurability): MessageDataStoreMessage[]{
    return this._allMsg.filter(m => m.durability === durability);
  }

  getMsgTextsBySeverityAndDurability(severity: MessageSeverity, durability: MessageDurability): string[]{
    return this.getMsgsBySeverityAndDurability(severity, durability).map(m => m.message);
  }

  /** global - but not permanent - success messages */
  get successMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("success", "temporary");
  }

  /** permanent success messages */
  get permanentSuccessMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("success", "permanent");
  }

  /** global - but not permanent - error messages */
  get errorMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("error", "temporary");
  }

  /** permanent (and global) error messages  */
  get permanentErrorMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("error", "permanent");
  }

  /** global - but not permanent - neutral messages */
  get neutralMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("neutral", "temporary");
  }

  /** permanent (and global) neutral messages  */
  get permanentNeutralMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("neutral", "permanent");
  }

  /** global - but not permanent - warning messages */
  get warningMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("warning", "temporary");
  }

  /** permanent (and global) warning messages  */
  get permanentWarningMsgs(): string[] {
    return this.getMsgTextsBySeverityAndDurability("warning", "permanent");
  }

  /** Optional success headline */
  get successHeadline(): string | undefined {
    return this._allHeadlines.get("success");
  }

  /** Optional error headline */
  get errorHeadline(): string | undefined {
    return this._allHeadlines.get("error");
  }

  /** Optional neutral headline */
  get neutralHeadline(): string | undefined {
    return this._allHeadlines.get("neutral");
  }

  /** Optional warning headline */
  get warningHeadline(): string | undefined {
    return this._allHeadlines.get("warning");
  }

  /** all global (permanent and not permanent) error messages */
  get allErrorMsg(): string[] {
    return [...this.errorMsgs, ...this.permanentErrorMsgs];
  }

  /** all global (permanent and not permanent) success */
  get allSuccessMsg(): string[] {
    return [...this.successMsgs, ...this.permanentSuccessMsgs];
  }

  /** all global (permanent and not permanent) neutral */
  get allNeutralMsg(): string[] {
    return [...this.neutralMsgs, ...this.permanentNeutralMsgs];
  }

  /** all global (permanent and not permanent) warning */
  get allWarningMsg(): string[] {
    return [...this.warningMsgs, ...this.permanentWarningMsgs];
  }

  scrollToTop(): void {
    window?.scrollTo(0, 0);
  }

  clearMsgsBySeverityAndDurability(severity: MessageSeverity, durability: MessageDurability): void{
    this.getMsgsBySeverityAndDurability(severity, durability).forEach(msg => this._allMsg.remove(msg));
  }

  clearMsgsByDurability(durability: MessageDurability): void{
    this.getMsgsByDurability(durability).forEach(msg => this._allMsg.remove(msg));
  }


  clearTemporaryMessages(): void {
    this.clearMsgsByDurability("temporary");
    logger.log(LOG_MODULE, "cleared temp messages");
  }

  clearErrorMessages(): void {
    this.clearBySeverity("error");
    logger.log(LOG_MODULE, "cleared error messages");
  }

  clearSuccessMessages(): void {
    this.clearBySeverity("success");
    logger.log(LOG_MODULE, "cleared success messages");
  }

  clearBySeverity(severity: MessageSeverity){
    this.clearMsgsBySeverityAndDurability(severity, "permanent");
    this.clearMsgsBySeverityAndDurability(severity, "temporary");
    this._allHeadlines.delete(severity);
  }


  clearNeutralMessages(): void {
    this.clearBySeverity("neutral");
    logger.log(LOG_MODULE, "cleared neutral messages");
  }

  clearWarningMessages(): void {
    this.clearBySeverity("warning");
    logger.log(LOG_MODULE, "cleared warning messages");
  }

  clearPermanentMessages(): void {
    this.clearMsgsByDurability("permanent");
    logger.log(LOG_MODULE, "cleared perm messages");
  }

  addSuccessMessagesWithHeadline(headline: string, messages: string[]) {
    this._allHeadlines.set("success", headline);
    messages.forEach(msg => this.addSuccessMessage(msg));
  }

  addErrorMessagesWithHeadline(headline: string, messages: string[]) {
    this._allHeadlines.set("error", headline);
    messages.forEach(msg => this.addErrorMessage(msg));
  }

  addNeutralMessagesWithHeadline(headline: string, messages: string[]) {
    this._allHeadlines.set("neutral", headline);
    messages.forEach(msg => this.addNeutralMessage(msg));
  }

  addWarningMessagesWithHeadline(headline: string, messages: string[]) {
    this._allHeadlines.set("warning", headline);
    messages.forEach(msg => this.addWarningMessage(msg));
  }
  
  addSuccessMessage(msg: string): void {
    this.addMessage({message: msg, severity: "success", durability: "temporary"});
    logger.log(LOG_MODULE, "added success message");
    this.scrollToTop();
  }

  addErrorMessage(msg: string): void {
    this.addMessage({message: msg, severity: "error", durability: "temporary"});
    logger.log(LOG_MODULE, "added error message");
    this.scrollToTop();
  }

  addNeutralMessage(msg: string): void {
    this.addMessage({message: msg, severity: "neutral", durability: "temporary"});
    logger.log(LOG_MODULE, "added neutral message");
    this.scrollToTop();
  }

  addWarningMessage(msg: string): void {
    this.addMessage({message: msg, severity: "warning", durability: "temporary"});
    logger.log(LOG_MODULE, "added warning message");
    this.scrollToTop();
  }

  addPermanentSuccessMessage(msg: string): void {
    this.addMessage({message: msg, severity: "success", durability: "permanent"});
    logger.log(LOG_MODULE, "added perm success message");
    this.scrollToTop();
  }

  addPermanentErrorMessage(msg: string): void {
    this.addMessage({message: msg, severity: "error", durability: "permanent"});
    logger.log(LOG_MODULE, "added perm error message");
    this.scrollToTop();
  }

  addPermanentNeutralMessage(msg: string): void {
    this.addMessage({message: msg, severity: "neutral", durability: "permanent"});
    logger.log(LOG_MODULE, "added perm neutral message");
    this.scrollToTop();
  }

  addPermanentWarningMessage(msg: string): void {
    this.addMessage({message: msg, severity: "warning", durability: "permanent"});
    logger.log(LOG_MODULE, "added perm warning message");
    this.scrollToTop();
  }

  getSuccessMsgs(): string[] {
    return this.successMsgs;
  }

  getPermanentSuccessMsgs(): string[] {
    return this.permanentSuccessMsgs;
  }

  getErrorMsgs(): string[] {
    return this.errorMsgs;
  }

  getPermanentErrorMsgs(): string[] {
    return this.permanentErrorMsgs;
  }

  getNeutralMsgs(): string[] {
    return this.neutralMsgs;
  }

  getPermanentNeutralMsgs(): string[] {
    return this.permanentNeutralMsgs;
  }

  getWarningMsgs(): string[] {
    return this.warningMsgs;
  }

  getPermanentWarningMsgs(): string[] {
    return this.permanentWarningMsgs;
  }

  clear(): void {
    this._allMsg.clear();
    this._allHeadlines.clear();
  }

  clearHeadlines() {
    this._allHeadlines.clear();
  }

  get successMsgCount(): number {
    const count = this.successMsgs.length
        + this.permanentSuccessMsgs.length;

    logger.log(LOG_MODULE, "success count ", count);

    return count;
  }

  get errorMsgCount(): number {
    const count = this.errorMsgs.length
        + this.permanentErrorMsgs.length;

    logger.log(LOG_MODULE, "error count ", count);

    return count;
  }

  get neutralMsgCount(): number {
    const count = this.neutralMsgs.length
        + this.permanentNeutralMsgs.length;

    logger.log(LOG_MODULE, "neutral count ", count);

    return count;
  }

  get warningMsgCount(): number {
    const count = this.warningMsgs.length
        + this.permanentWarningMsgs.length;

    logger.log(LOG_MODULE, "warning count ", count);

    return count;
  }
}
