/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";
import { getFeatureToggleProvider } from "./FeatureToggleProvider";

type RequestedFts<RequestedFtNames extends string[]> = { [ftName in RequestedFtNames[number]]: boolean };

/**
 * Hook for using standard (non critical, default value false) feature toggles.
 *
 * @param {string} requestedFtNames names of feature toggles
 * @returns {RequestedFts<RequestedFtNames>} object mapping every element of `requestedFtNames` to a boolean.
 * @example
 * const {feature1, feature2} = useStandardFeatureToggles("feature1", "feature2");
 */
export function useStandardFeatureToggles<RequestedFtNames extends string[]>(...requestedFtNames: RequestedFtNames): RequestedFts<RequestedFtNames> {
  const featureToggleProvider = getFeatureToggleProvider();

  const [featureToggles, setFeatureToggles] = useState<RequestedFts<RequestedFtNames>>(
      Object.fromEntries(
          requestedFtNames.map(ftName => [ftName, featureToggleProvider.getIfPresent(ftName) ?? false])
      ) as RequestedFts<RequestedFtNames>
  );

  useEffect(() => {
    const subscribers = requestedFtNames.map(ftName => [
      ftName,
      (newValueOrError: boolean | Error) => setFeatureToggles(previousFeatureToggles => ({
        ...previousFeatureToggles,
        // in case of error assume false
        [ftName]: typeof newValueOrError === "boolean" ? newValueOrError : false
      }))
    ] as const);
    subscribers.forEach(([ftName, subscriber]) => featureToggleProvider.subscribe(ftName, subscriber));
    return () => subscribers.forEach(([ftName, subscriber]) => featureToggleProvider.unsubscribe(ftName, subscriber));
  }, [setFeatureToggles, featureToggleProvider]);

  return featureToggles;
}

/**
 * Hook for using critical feature toggles.
 *
 * @param {string} requestedFtNames names of feature toggles
 * @returns {RequestedFts<RequestedFtNames>} undefined iff the value of any element in `requestedFtNames` is not known yet.
 * @example
 * // returns undefined iff a feature is not loaded yet. Therefore, do not destructure directly!
 * const featureToggles = useCriticalFeatureToggles("feature1", "feature2");
 * if(featureToggles === undefined) {
 *   return null; // render nothing
 * }
 * const {feature1, feature2} = featureToggles;
 */
export function useCriticalFeatureToggles<RequestedFtNames extends string[]>(
    ...requestedFtNames: RequestedFtNames
): RequestedFts<RequestedFtNames> | undefined {
  const featureToggleProvider = getFeatureToggleProvider();

  // aggregator for feature toggles. some of them might be undefined until they are loaded/cached
  const [featureToggles, setFeatureToggles] = useState<Partial<RequestedFts<RequestedFtNames>>>(
      Object.fromEntries(
          requestedFtNames.map(ftName => [ftName, featureToggleProvider.getIfPresent(ftName)])
      ) as Partial<RequestedFts<RequestedFtNames>>
  );

  useEffect(() => {
    const subscribers = requestedFtNames.map(ftName => [
      ftName,
      (newValueOrError: boolean | Error) => {
        setFeatureToggles(previousFeatureToggles => ({
          ...previousFeatureToggles,
          [ftName]: typeof newValueOrError === "boolean" ? newValueOrError : undefined
        }));

        if (newValueOrError instanceof Error) {
          throw newValueOrError; // let it bubble
        }
      }
    ] as const);
    subscribers.forEach(([ftName, subscriber]) => featureToggleProvider.subscribe(ftName, subscriber));
    return () => subscribers.forEach(([ftName, subscriber]) => featureToggleProvider.unsubscribe(ftName, subscriber));
  }, [setFeatureToggles, featureToggleProvider]);

  // iff all requested feature toggles are available, return them. Otherwise, return undefined.
  return requestedFtNames.every((ftName: RequestedFtNames[number]) => featureToggles[ftName] !== undefined)
      ? featureToggles as RequestedFts<RequestedFtNames> // without Partial<...>
      : undefined;
}
