/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, autorun, computed, IComputedValue, makeObservable, observable, reaction } from "mobx";
import { Key } from "react";
import { extractObjectData, logger, setObjectData } from "..";
import { TableCellType } from "../components/atoms/DHLTableCell/DHLTableCell";
import { getColSorterForType, SortFunction, SortOrder } from "../utils/dynamicCellSort";
import { normalizeFilter } from "../utils/stringUtils";
import { BaseDataStore } from "./BaseDataStore";
import { MessageDataStore } from "./MessageDataStore";
import { PaginationDataStore } from "./PaginationDataStore";
import { ResourceDataStore } from "./ResourceDataStore";
import { ValidationRuleDataStore } from "./ValidationRuleDataStore";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { TFunction } from "i18next";

type LoadingStateType = "NOT_LOADED" | "LOADED" | "LOADING";

export const filterTextsSymbol = Symbol.for("filterTexts");
export type TableRowType<T> = T & { [filterTextsSymbol]?: string[] }

export class TableDataStore<T extends {}> {

  /** Die vollständigen Daten. */
  private _completeData: TableRowType<T>[] = [];

  private readonly filterPredicate: IComputedValue<(row: TableRowType<T>) => boolean>;

  /** Der aktuell angezeigte Ausschnitt gemäß Filterung. */
  private get dataAfterFilter(): TableRowType<T>[] {
    return this._completeData.filter(this.filterPredicate.get()).slice();
  }

  /** Der aktuell angezeigte Ausschnitt gemäß Filterung und Sortierung - jedoch nicht slicing. */
  public get currentData(): TableRowType<T>[] {
    if (this.sortFunction !== null && this._sortPropertyName !== null) {
      return this.dataAfterFilter
          .sort(this.sortFunction(this.baseDataStore.resourceDataStore, this._sortPropertyName, this._sortOrder))
          .slice();
    } else {
      return this.dataAfterFilter.slice();
    }
  }

  public getSelectedCountAll(propertyName: string): number {
    return this.currentData.filter(d => extractObjectData(d, propertyName)).length;
  }

  public getSelectedCountVisible(propertyName: string): number {
    return this.currentSlice.filter(d => extractObjectData(d, propertyName)).length;
  }

  private readonly filterTextsMapper: ((row: T) => string[]) | null;

  private _sortPropertyName: string | null = null;

  private _sortColumnKey: Key | null = null;

  private _sortOrder: SortOrder = SortOrder.ASC;

  private sortFunction: ((resourceDataStore: ResourceDataStore | TFunction | null,
                          propertiesName: string,
                          sortOrder: SortOrder) => SortFunction<T>) | null = null;

  public baseDataStore: BaseDataStore;

  public paginationDataStore: PaginationDataStore;

  public currentRowId: string | null = null;

  public loadingState: LoadingStateType = "NOT_LOADED";

  public showDelete: boolean = false;

  public usePager: boolean = true;

  /**
   * Konstruktor.
   *
   * @param resourceDataStore Message-Ressource mit lokalisierten Texten
   * @param validationRuleDataStore Validierungsregeln
   * @param messageDataStore wenn ein übergeordneter Message-DataStore verwendet werden soll
   * @param authenticationManager zum Zugriff auf die Sprache des Nutzers
   * @param filterPredicate die zu verwendende Filtermethode
   * @param showFieldErrors true wenn die Fehlermeldungen bei den Eingabefeldern angezeigt werden sollen, sonst false
   * @param filterTextMapper Funktion, die eine Zeile auf den bei der Filterung zu durchsuchenden Texte abbildet
   */
  constructor(
      resourceDataStore: ResourceDataStore | TFunction,
      validationRuleDataStore: ValidationRuleDataStore,
      messageDataStore: MessageDataStore | null | undefined,
      authenticationManager: AuthenticationManager | null,
      filterPredicate: IComputedValue<(row: TableRowType<T>) => boolean>,
      showFieldErrors: boolean = true,
      filterTextMapper: ((row: T) => string[]) | null = null) {
    this.baseDataStore = new BaseDataStore(resourceDataStore, validationRuleDataStore, messageDataStore, showFieldErrors);
    this.paginationDataStore = new PaginationDataStore(resourceDataStore);
    this.filterPredicate = filterPredicate;
    this.filterTextsMapper = filterTextMapper && ((row: T) => filterTextMapper(row).map(normalizeFilter));

    makeObservable<TableDataStore<T>, "_completeData" | "filterPredicate" | "dataAfterFilter" | "filterTextsMapper" | "_sortPropertyName" |
        "_sortColumnKey" | "_sortOrder" | "sortFunction">(this, {
      _completeData: observable,
      filterPredicate: observable,
      dataAfterFilter: computed,
      currentData: computed,
      filterTextsMapper: observable,
      _sortPropertyName: observable,
      _sortColumnKey: observable,
      _sortOrder: observable,
      sortFunction: observable,
      baseDataStore: observable,
      paginationDataStore: observable,
      currentRowId: observable,
      loadingState: observable,
      showDelete: observable,
      setFieldValue: action,
      completeData: computed,
      setData: action,
      sortColumnKey: computed,
      sortOrder: computed,
      sortPropertyName: computed,
      sort: action,
      resetSortAndFilters: action,
      currentSlice: computed,
      updateCell: action,
      switchToLoaded: action,
      switchToLoading: action,
      switchToNotLoaded: action
    });

    reaction(
        () => authenticationManager?.language,
        () => this._completeData.forEach(row => row[filterTextsSymbol] = this.filterTextsMapper?.(row))
    );

    autorun(() => {
      this.paginationDataStore.setNoOfEntries(this.currentData.length);
      this.paginationDataStore.adjustCurrentPage();
    });
  }

  setFieldValue(index: number, attributeName: string, value: any) {
    this.currentData[index][attributeName as keyof T] = value;
  }

  /**
   * clones complete data for display
   */
  public get completeData(): TableRowType<T>[] {
    return this._completeData.slice();
  }

  public setData(completeData: T[]) {
    this._completeData = completeData.map(row => Object.assign(row, {
      [filterTextsSymbol]: this.filterTextsMapper?.(row)
    }));
  }

  public get sortOrder() {
    return this._sortOrder;
  }

  public get sortPropertyName() {
    return this._sortPropertyName;
  }

  public get sortColumnKey() {
    return this._sortColumnKey;
  }

  public sort(
      sortPropertyName: string,
      sortOrder: SortOrder,
      sortPropertyType: TableCellType,
      columnKey?: Key | null,
      comparator?: (resourceDataStore: ResourceDataStore | TFunction | null,
                    propertiesName: string,
                    sortOrder: SortOrder) => SortFunction<T>
  ) {
    // sortPropertyType kann sich nur ändern, wenn sich auch sortPropertyName ändert
    if (this._sortPropertyName !== sortPropertyName || this._sortOrder !== sortOrder || this._sortColumnKey !== columnKey) {
      this._sortPropertyName = sortPropertyName;
      this._sortOrder = sortOrder;
      this._sortColumnKey = columnKey ?? null;
      this.sortFunction = comparator ?? getColSorterForType<T>(sortPropertyType);
    }
  }

  public resetSortAndFilters(): void {
    this._sortPropertyName = null;
    this._sortColumnKey = null;
    this._sortOrder = SortOrder.ASC;
    this.sortFunction = null;
  }

  get currentSlice(): T[] {
    if (this.usePager && !this.paginationDataStore.showAll) {
      return this.currentData.slice(
          (this.paginationDataStore.currentPage - 1) * this.paginationDataStore.currentPageSize,
          this.paginationDataStore.currentPage * this.paginationDataStore.currentPageSize
      );
    }
    return this.currentData.slice();
  }

  updateCell(cellId: string, newValue: any, idPropertyName: string, colPropertyName: string): boolean {
    const objectId: string = cellId.slice(0, cellId.lastIndexOf("-"));

    // objectID ist immer ein String und extractObjectData liefert das Zeilenobjekt mit dem zugehörigen Datentyp.
    // Der kann dann auch mal number oder ähnliches sein.
    const currentRow = this.currentData.find(row => objectId === String(extractObjectData(row, idPropertyName)));
    if (!currentRow) {
      logger.error(new Error(`Cell update failed: cannot find row with "${idPropertyName}" = "${cellId}"`).stack);
      return false;
    }

    setObjectData(currentRow, colPropertyName, newValue);
    Object.assign(currentRow, {[filterTextsSymbol]: this.filterTextsMapper?.(currentRow)});

    return true;
  }

  switchToLoaded(): void {
    this.loadingState = "LOADED";
  }

  switchToLoading(): void {
    this.loadingState = "LOADING";
  }

  switchToNotLoaded(): void {
    this.loadingState = "NOT_LOADED";
  }
}
