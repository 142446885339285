/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { FetchAdapter } from "../utils/fetch/FetchAdapter";
import { logger } from "../utils/logger";
import { KeyValueType, LanguageType, SelectionListType } from "../types/DataTypes";
import { SelectionListClient } from "./SelectionListClient";

const LOG_MODULE = "ResourceDataClient";

export class ResourceDataClient {
  constructor(
      readonly fetchAdapter: FetchAdapter,
      private readonly baseUrl: string,
      private readonly selectionListClient: SelectionListClient = new SelectionListClient(fetchAdapter, baseUrl)) {
  }

  public async fetchLocalizations(language: LanguageType, module: string): Promise<KeyValueType[]> {
    logger.log(LOG_MODULE, "loading localizations for " + module);

    const response = await this.fetchAdapter.get(`${this.baseUrl}/localizations/localizations?language=${language}&module=${module}`);

    if (response.ok || response.status === 304) {
      return (await response.json()) as KeyValueType[];
    } else {
      logger.warn(LOG_MODULE, "loading localizations for " + module + " failed (code:" + response.status + ")");
      return [];
    }
  }

  /** @deprecated use SelectionListClient instead **/
  public async fetchSelectionLists(language: LanguageType): Promise<SelectionListType[]> {
    return this.selectionListClient.fetchSelectionLists(language);
  }
}