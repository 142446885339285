/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { ReactElement, useEffect, useRef, useState } from "react";
import { createMessageWithSpecialTags, DHLContextmenu, DHLContextmenuGroup, DHLContextmenuLabel, DHLTooltip, TableDataStore } from "../../..";
import { SortOrder } from "../../../utils/dynamicCellSort";
import { genericObserver } from "../../../utils/genericObserver";
import { DHLIcon, IconType } from "../../atoms/DHLIcon/DHLIcon";
import "./DHLTableHeaderCol.scss";
import { isResourceDataStoreTAdapter } from "../../../utils/i18next";

export type DHLTableHeaderColProps<T extends {}> = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Tabellen Datenspeicher. */
  dataStore: TableDataStore<T>;

  /** Spaltendefinition */
  colItem: ReactElement;

  /** Simple sorting mode without context menu */
  complexSortingEnabled?: boolean;
};

function calcColumnSpecificClasses(title: any, type: any) {
  if (!title) {
    if ("iconButton" === type) {
      return "fixedColumnHeader-20px no-horizontal-padding";
    }
    if ("boolean" === type) {
      return "fixedColumnHeader-20px";
    }
  }
  return null;
}

function calcSortIcon(isSortAsc: () => boolean | "" | null, isSortDesc: () => boolean | "" | null, complexSortingEnabled: undefined | boolean) {
  return (isSortAsc() && "asc") || (isSortDesc() && "desc") || (!complexSortingEnabled && "simple") ||
      undefined;
}

/** Ausgabe einer Tabellen-Headerspalte. */
export const DHLTableHeaderCol = genericObserver(function <T extends {}>(
    {
      name,
      dataStore,
      colItem,
      complexSortingEnabled = false
    }: DHLTableHeaderColProps<T>
) {
  const {title, type, sortable, propertiesName, contentAlignment, disableMinWidth, comparator, titleTooltip, titleTooltipPlacement}
      = colItem.props;
  const [contextmenuOpen, setContextmenuOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleContextmenu = () => setContextmenuOpen(!contextmenuOpen);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  let alignmentClass = null;
  if (contentAlignment) {
    alignmentClass = "align-" + contentAlignment;
  }
  function isSortThisColumn() {
    const columnKey = dataStore.sortColumnKey;
    return dataStore.sortPropertyName && dataStore.sortPropertyName === propertiesName && (!columnKey || columnKey === colItem.key);
  }

  const isSortAsc = () => {
    return dataStore.sortOrder === SortOrder.ASC && isSortThisColumn();
  };
  const isSortDesc = () => {
    return dataStore.sortOrder === SortOrder.DESC && isSortThisColumn();
  };

  const renderSorter = () => {
    if (sortable) {
      return <DHLContextmenuGroup
          headline={isResourceDataStoreTAdapter(dataStore.baseDataStore.resourceDataStore)
              ? dataStore.baseDataStore.resourceDataStore.t("table.sort.title")
              : dataStore.baseDataStore.resourceDataStore.getMsg("tables.contextmenu.sortHeading")}>
        {_renderSortAsc()}
        {_renderSortDesc()}
      </DHLContextmenuGroup>;
    } else {
      return null;
    }
  };

  const _renderSortAsc = () => {
    let sortAsc = null;

    if (sortable) {
      const options: LabelVariableOptions = {
        checked: false,
        onClick: undefined
      };
      if (isSortAsc()) {
        options.checked = true;
      } else {
        options.checked = false;
        options.onClick = (e: React.MouseEvent) => {
          dataStore.sort(propertiesName, SortOrder.ASC, type, colItem.key, comparator);
          setContextmenuOpen(false);
          e.preventDefault();
        };
      }
      sortAsc = (
          <DHLContextmenuLabel
              name={name + "-sort" + (isSortAsc() ? "ed" : "") + "-asc-" + propertiesName}
              label={isResourceDataStoreTAdapter(dataStore.baseDataStore.resourceDataStore)
                  ? dataStore.baseDataStore.resourceDataStore.t("table.sort.ascending")
                  : dataStore.baseDataStore.resourceDataStore.getMsg("tables.contextmenu.sortAscending")}
              {...options}
          />
      );
    }

    return sortAsc;
  };

  const _renderSortDesc = () => {
    let sortDesc = null;

    if (sortable) {
      const options: LabelVariableOptions = {
        checked: false,
        onClick: undefined
      };
      if (isSortDesc()) {
        options.checked = true;
      } else {
        options.checked = false;
        options.onClick = (e: React.MouseEvent) => {
          dataStore.sort(propertiesName, SortOrder.DESC, type, colItem.key, comparator);
          setContextmenuOpen(false);
          e.preventDefault();
        };
      }
      sortDesc = (
          <DHLContextmenuLabel
              name={name + "-sort" + (isSortDesc() ? "ed" : "") + "-desc-" + propertiesName}
              label={isResourceDataStoreTAdapter(dataStore.baseDataStore.resourceDataStore)
                  ? dataStore.baseDataStore.resourceDataStore.t("table.sort.descending")
                  : dataStore.baseDataStore.resourceDataStore.getMsg("tables.contextmenu.sortDescending")}
              {...options}
          />
      );
    }

    return sortDesc;
  };

  const sorter = renderSorter();

  const headerCellRef = useRef(null as any);

  // contextmenu should close on click outside of it
  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (headerCellRef.current && !headerCellRef.current.contains(e.target)) {
        setContextmenuOpen(false);
      }
    };

    if (sortable) {
      document.addEventListener("mousedown", onClickOutside);
      return () => {
        document.removeEventListener("mousedown", onClickOutside);
      };
    } else {
      return () => { /* intended use */ };
    }
  }, [headerCellRef, sortable]);

  let inner: JSX.Element;

  const onSimpleSortClicked: () => void = () => {
    if (isSortThisColumn() && isSortAsc()) {
      dataStore.sort(propertiesName, SortOrder.DESC, type, colItem.key, comparator);
    } else {
      dataStore.sort(propertiesName, SortOrder.ASC, type, colItem.key, comparator);
    }
  };

  const sortColumn: () => void = () => {
    if (complexSortingEnabled) {
      toggleContextmenu();
    } else {
      onSimpleSortClicked();
    }
  };

  const renderSortingContextMenu: () => JSX.Element = () => {
    return (
        <div className="dhlTableheaderCell-anchorContextmenu">
          <DHLContextmenu
              visible={contextmenuOpen}>
            {sorter}
          </DHLContextmenu>
        </div>
    );
  };

  const tooltipElement = titleTooltip ?
      <>
        <DHLIcon name={"dhlTableheaderCellInfoIcon" + name} icon={"alert-info"} className={"label-tooltip-icon"} />
        <DHLTooltip
            placement={titleTooltipPlacement}
            tooltipOpen={tooltipOpen}
            target={"dhlTableheaderCellInfoIcon" + name}
            toggle={toggleTooltip}>
          {titleTooltip}
        </DHLTooltip>
      </>
      : null;

  const innerDivClassnames = classNames("dhlTableheaderCell-inner", alignmentClass);
  if (sortable) {
    // dhlTableheaderCell-inner ist kein Button gerade, weil Kontextmenu Buttons enthält und Nesting von
    // Button/Interaktiven in Buttons nicht erlaubt ist
    inner =
        <div className={innerDivClassnames}>
          <LabelSortable
              label={title}
              icon={calcSortIcon(isSortAsc, isSortDesc, complexSortingEnabled)} />
          {complexSortingEnabled && renderSortingContextMenu()}
          {tooltipElement}
        </div>;
  } else {
    inner = <div className={innerDivClassnames}>{createMessageWithSpecialTags(title)}{tooltipElement}</div>;
  }

  return <th data-testid={name}
             id={"dhlTableheaderCell" + name}
             ref={headerCellRef}
             scope="col"
             className={classNames(
                 "dhlTableheaderCell",
                 sortable ? "dhlTableheaderCell-interactable" : null,
                 disableMinWidth ? "noMinWidth" : null,
                 calcColumnSpecificClasses(title, type)
             )}
             tabIndex={sortable ? 0 : undefined}
             onClick={sortable ? sortColumn : undefined}
             role={sortable ? "button" : undefined}>
    {inner}
  </th>;
});

type LabelVariableOptions = {
  checked: boolean,
  onClick: undefined | any
}

type SortIconType = "default" | "asc" | "desc" | "simple" | "option";

type LabelSortableProps = {
  /** mit klickbare Labelbeschriftung*/
  label?: string
  /** wenn sortierung aktiv, welche*/
  icon?: SortIconType
};

/** Button mit integriertem Tooltip. */
const LabelSortable = ({label, icon}: LabelSortableProps) => {

  let iconName: IconType;
  switch (icon) {
    case "desc":
      iconName = "sort-arrow-down";
      break;
    case "asc":
      iconName = "sort-arrow-up";
      break;
    case "simple":
      iconName = "no-sort-arrow";
      break;
    case "option":
      iconName = "option";
      break;
    case "default":
    default:
      iconName = "more-functions";
      break;
  }
  const iconNode = <DHLIcon className={"label-sort-icon"} name={""} icon={iconName} />;

  return (
      <>
        <span className={"label-text"}>{createMessageWithSpecialTags(label)}</span>
        {iconNode}
      </>
  );
};