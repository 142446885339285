/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import {IconType} from "../DHLIcon/DHLIcon";
import {DHLTableCellCommonProps} from "./DHLTableCell";

export type DHLTableCellIconButtonProps = DHLTableCellCommonProps & {
  /** Spaltentitel. */
  title?: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** CSS-Klassen. */
  className?: string;

  /** Funktion wird bei Klick auf ein Icon aufgerufen. */
  onClick: (event: React.MouseEvent, value: any) => void;

  /** Name of the icon to use. */
  iconName?: IconType;

  /** Function for dynamic computation of icon to use. */
  dynamicIconNameFunction?: (value: any) => IconType;

  /** Tooltip für Links. */
  tooltip?: string | React.ReactNode;

  /** Function for dynamic computation of tooltip text. */
  dynamicTooltipFunction?: (value: any) => string | React.ReactNode;

  /** Tooltip für deaktivierte Links, Buttons. */
  disabledTooltip?: string | React.ReactNode;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** If true cell is displayed disabled */
  isDisabled?: (value: any) => boolean

  /** Zelle auf Basis dieses Row-Properties disablen. */
  disabledPropertiesName?: string;

  /** If true cell is displayed */
  isVisible?: (value: any) => boolean;
}

/** Tabellenspaltendefinition - Icon-Button. */
export const DHLTableCellIconButton = (props: DHLTableCellIconButtonProps) => {
  return null;
};

DHLTableCellIconButton.defaultProps = {
  type: "iconButton",
  contentAlignment: "center",
  disableMinWidth: true,
};
