/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import { observer } from "mobx-react-lite";
import { DHLPagetemplate } from "../DHLPageTemplate/DHLPagetemplate";
import { DHLAlert } from "../../atoms/DHLAlert/DHLAlert";
import { DHLTextOutput } from "../DHLTextOutput/DHLTextOutput";
import { createMessageWithSpecialTags } from "../../../utils/messagesUtils";
import { useLocaleManager } from "../../../locale/useLocaleManager";

export type DHLMissingAuthorizationErrorPageProps = {
  /** Möglicher Text welcher im Alert angezeigt werden soll ansonsten default. */
  message?: any;
}

const ERROR_MESSAGE_DE = "Sie haben keine Berechtigung für die von Ihnen aufgerufene Seite.[br]" +
    "Bitte wenden Sie sich an Ihren Kundenadministrator, um die Berechtigungen Ihres Benutzers prüfen zu lassen.";
const ERROR_MESSAGE_EN = "You do not have authorization for the page you have requested.[br]" +
    "Please contact your customer administrator to have your user's authorizations checked.";

export const DHLMissingAuthorizationErrorPage = observer((
    {message}: DHLMissingAuthorizationErrorPageProps
) => {
  const localeManager = useLocaleManager();

  return (
      <DHLPagetemplate>
        <DHLAlert type={"error"} variant={"flex"}>
          <DHLTextOutput
              name={"error"}
              value={message ?? createMessageWithSpecialTags(localeManager.language === "de" ? ERROR_MESSAGE_DE : ERROR_MESSAGE_EN)}
          />
        </DHLAlert>
      </DHLPagetemplate>
  );
});
