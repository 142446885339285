/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ErrorResponse } from "../../stores/BaseDataStore";
import { logger } from "../logger";
import { addHeaders } from "./addHeaders";
import { AuthenticationManager } from "@gkuis/gkp-authentication";

export class FetchAdapter {
  readonly authenticationManager?: AuthenticationManager;

  constructor(authenticationManager?: AuthenticationManager) {
    this.authenticationManager = authenticationManager;
  }

  private async doFetch(
      url: string,
      options: RequestInit & { method: string },
      doNotSetRequestContentType: boolean = false,
      doNotRefreshToken: boolean = false
  ): Promise<Response> {
    const opts = await addHeaders(options, this.authenticationManager, doNotSetRequestContentType, doNotRefreshToken);
    return await fetch(url, opts);
  }

  async getFile(urlString: string, method?: string, options?: RequestInit): Promise<Response> {
    try {
      const {blob, filename, response} = await this.getBlob(urlString, method, options);
      this.openDownloadPrompt(blob, filename);
      return response;
    } catch (error) {
      logger.error(`Beim GET von File ${urlString} ist ein Fehler aufgetreten:`, error);
      throw FetchAdapter.createErrorResponse("fetchServiceGetFile");
    }
  }

  async getBlobUrl(urlString: string, fileName: string, fileOptions?: FilePropertyBag, method?: string, fetchOptions?: RequestInit): Promise<string> {
    try {
      const {blob} = await this.getBlob(urlString, method, fetchOptions);
      const file = new File([blob], fileName, fileOptions);
      const url = window.URL.createObjectURL(file);
      setTimeout(() => {
        // Für FF muss gewartet werden
        window.URL.revokeObjectURL(url);
      }, 200);
      return url;
    } catch (error) {
      logger.error(`Beim GET von BlobUrl ${urlString} ist ein Fehler aufgetreten:`, error);
      throw FetchAdapter.createErrorResponse("fetchServiceGetFile");
    }
  }

  private async getBlob(urlString: string, method?: string, options?: RequestInit): Promise<{ blob: Blob, filename: string, response: Response }> {
    try {
      if (!options) {
        options = {
          headers: {
            "Cache-Control": "no-cache,no-store"
          }
        };
      }
      const response = await this.doFetch(urlString, {method: method ?? "GET", ...(options || {})});

      if (!response.ok) {
        return Promise.reject(response);
      }

      const {contentType, filename} = FetchAdapter.extractData(response);
      const newBlob: Blob = await FetchAdapter.createBlob(contentType, response);
      logger.log("Downloaded file: ", filename, contentType, response.headers);

      return {filename: filename, blob: newBlob, response: response};
    } catch (error) {
      logger.error(`Beim ${method ?? "GET"} von Blob ${urlString} ist ein Fehler aufgetreten:`, error);
      const errorResponse: ErrorResponse = FetchAdapter.createErrorResponse("fetchServiceGetFile");
      return Promise.reject(new Response(JSON.stringify(errorResponse), {status: 503}));
    }
  }

  private static createErrorResponse(errorKey: string) {
    return {
      globalError: true,
      errorMessages: [
        {
          messageKey: `framework.error.${errorKey}`
        }
      ]
    };
  }

  private openDownloadPrompt(newBlob: Blob, filename: string) {
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      // Für FF muss gewartet werden
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  private static extractData(response: Response) {
    let contentDisposition: string | null = response.headers.get("Content-Disposition") ?? response.headers.get("content-disposition");
    let contentType: string | null = response.headers.get("Content-Type") ?? response.headers.get("content-type");
    const filename = FetchAdapter.extractFilename(contentDisposition);
    return {contentType, filename};
  }

  private static async createBlob(contentType: string | null, response: Response) {
    let newBlob;

    if (contentType != null) {
      newBlob = new Blob([await response.blob()], {type: contentType});
    } else {
      newBlob = new Blob([await response.blob()]);
    }
    return newBlob;
  }

  private static extractFilename(contentDisposition: string | null) {
    let filename: string = "default.txt";
    if (contentDisposition != null) {
      filename = contentDisposition.split("filename=")[1];

      if (filename.startsWith("\"")) {
        filename = filename.substring(1);
      }

      if (filename.endsWith("\"")) {
        filename = filename.substring(0, filename.length - 1);
      }
    }
    return filename;
  }

  async get(url: string, options?: RequestInit, doNotRefreshToken = false): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "GET", ...(options || {})}, false, doNotRefreshToken);
    } catch (error) {
      logger.error(`Beim GET von ${url} ist ein Fehler aufgetreten:`, error);
      return Promise.resolve(new Response(JSON.stringify(FetchAdapter.createErrorResponse("fetchServiceGet")), {status: 503}));
    }
  }

  async post(url: string, options?: RequestInit, doNotSetRequestContentType: boolean = false): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "POST", ...(options || {})}, doNotSetRequestContentType);
    } catch (error) {
      logger.error(`Beim POST von ${url} ist ein Fehler aufgetreten:`, error);
      return Promise.resolve(new Response(JSON.stringify(FetchAdapter.createErrorResponse("fetchServicePost")), {status: 503}));
    }
  }

  async put(url: string, options?: RequestInit, doNotSetRequestContentType: boolean = false): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "PUT", ...(options || {})}, doNotSetRequestContentType);
    } catch (error) {
      logger.error(`Beim PUT von ${url} ist ein Fehler aufgetreten:`, error);
      return Promise.resolve(new Response(JSON.stringify(FetchAdapter.createErrorResponse("fetchServicePut")), {status: 503}));
    }
  }

  async delete(url: string, options?: RequestInit): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "DELETE", ...(options || {})});
    } catch (error) {
      logger.error(`Beim DELETE von ${url} ist ein Fehler aufgetreten:`, error);
      return Promise.resolve(new Response(JSON.stringify(FetchAdapter.createErrorResponse("fetchServiceDelete")), {status: 503}));
    }
  }

  async promisify<T>(wrappedResponse: Response): Promise<T> {
    if (wrappedResponse.status === 409) {
      logger.log("Received conflict response", await wrappedResponse.json());
      throw FetchAdapter.createErrorResponse("conflict");
    }

    if (!wrappedResponse.ok) {
      logger.log("Received error response", wrappedResponse.status);
      throw await wrappedResponse.json();
    }

    logger.log("Received ok response", wrappedResponse.status);

    if (wrappedResponse.status === 204) {
      return {} as T;
    }

    const body = await wrappedResponse.json() as T;
    if (body !== undefined && body !== null) {
      return body;
    }

    logger.error("Type conversion error", wrappedResponse.status);
    throw FetchAdapter.createErrorResponse("unknownType");
  }
}

export const fetchAdapterWithoutTokenAuthenticationHelper = new FetchAdapter(undefined);
